import styled from 'styled-components';

export const Container = styled.div`
    padding: 1em 3em 3em 0px;

    & > h2 {
    margin-bottom: 56px;
    }
`;

export const Content = styled.div`
  background-color: #FFF;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 1em;
`;

export const Table = styled.table`
    width: 100%;
    font-size: 14px;
    text-align: center;

    & > thead {
        background-color: #F5F6FA;
        height: 48px;
        color: #828282;
        font-weight: bold;
    }

    & > tbody {
        font-weight: 300;

        & > tr {
            height: 50px;

            & > td:last-child{
                height: 50px;
                display: flex;  
                align-items: center;
                justify-content: space-around;

                & > button {
                    /* width: 80%; */
                    height: 28px;
                    font-size: 12px;
                    border-radius: 5px;
                }

                & > button:nth-child(2){
                    background-color: #DC1076;
                }
            }
        }
    }
`;

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000031;
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  text-align: center;

  div {
    margin-bottom: 20px
  }
`;

export const ModalButton = styled.button`
  background: ${props => props.blue ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.red ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  height: 37px;
  font-weight: 600;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;
`

export const SearchContainer = styled.div`
  margin-bottom: 12px;

  input {
    margin-left: 6px;
    margin-right: 6px;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #828282;
    margin-right: 30px;
    padding: 7px;
  }
`;

export const ButtonSearch = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.verm ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 2px;
  padding: 0 5px;
  margin: 6px 6px;
`