import styled from 'styled-components'

export const ChartBar = styled.div`
`

export const AreaTotalChart = styled.div`
  background-color: #FFF;
  width: 46%;
  height: 420px;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  padding: 10px 0 0 10px;
  margin-bottom: 10px;
  margin-left: 4%;
  margin-top: 10px;

  span{
    height: 25px;
    margin: 20px 0 0 0;
    font-weight: bold;
  }
  `

  export const AreaInformacao = styled.div`
    margin: 20px 145px 0 0;
    span {
      font-weight: bold;
      font-size: 25px;
    }
    p {
        color: #24C855;
        font-weight: bold;
        margin-left: 3px;
    }
    img {
      margin-bottom: 1px;
    }
  `