import React from "react";
import { useHistory } from 'react-router-dom'
import { TableCustom, ButtonEdit } from './styles'

function TabelaAvaliacoes({
  mediation, handleHideMediation, path,
  loading, canEdit
}){
  const history = useHistory()
  
  function handleNavigation(item){
    history.push({
      pathname: `/editar-avaliacao/${path}`,
      state: item
    });
  }

  const styles = {
    paddingLeft: 26,
  }

  const style = { 
    width: 330,
  }

  return(
    <div>
      <TableCustom large>
        <thead>
          <th>Avaliador</th>
          <th>Avaliado</th>
          <th>Descrição</th>
          <th style={styles}>Funções</th>
        </thead>
        <tbody>
          {mediation.map((item, index) => (
            <tr key={item.id}>
              <td>{item.evaluator.name}</td>
              <td>{item.rated.name}</td>
              <td>{item.description}</td>
              <td style={style}>
                {canEdit ? 
                item.id === loading ? 
                <div className="text-center">
                  <div className="spinner-border text-info" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
                :
                <>
                  <ButtonEdit azul onClick={() => handleNavigation(item)}>EDITAR AVALIAÇÃO</ButtonEdit>
                  <ButtonEdit 
                  azul={item.hide}
                  onClick={() => handleHideMediation(item)}>
                    {item.hide ? `EXIBIR` : `OCULTAR`}
                  </ButtonEdit>
                </> 
                : null }
              </td>
            </tr>
          ))}
        </tbody>
      </TableCustom>
    </div>
  )
}

export default TabelaAvaliacoes;