import styled from 'styled-components'; 

export const Link = styled.a`
  text-decoration: none;
  color: #000;
`
export const SidebarTotal = styled.div`
  display: flex;
  max-width: 250px;
  min-width: 250px;
  height: 100%;
  padding-top: 5px;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  @media(max-width: 768px) {
    Button{
      display: flex;
    } 
    Button:focus{

    }
  }
`
export const IconStyle = styled.img`
  margin-left: 15px;
  margin-right: 15px;
`

export const MenuLateralArea = styled.ul`
  max-width: 250px;
  height: 100%;
  color: #000;
  height: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  cursor: pointer;
  @media(max-width: 768px){
  }

  `

  export const List1Styled = styled.li `
  margin: 0;
  width: 100%;
  height: 52px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  ${Link}:hover & {
    background: rgba(24, 239, 230, 0.1);
    color: #86fefa;
    border-left: 4px solid #86fefa;
  } 
`
export const List2Styled = styled.li `
  font-size: 15px !important;
  height: 30px;
  margin: 0;
  list-style-type: none;
  display: flex;
  padding-top: 5px; 

  &:hover {
    background: rgba(24, 239, 230, 0.1);
    color: #86fefa;
    border-left: 4px solid #86fefa;
  }
 
`
export const TitleSub = styled.div `
  margin-left: 4.5rem;
  height: 100%; 
  font-size: 12px;
`
export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 30px;
  margin-top: -2px;
  position: absolute;
  display: none;
`
