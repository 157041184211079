import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

import { Button, Container, ButtonArea } from './styles'
import TabelaAvaliacoes from '../../components/TabelaAvaliacoes';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getArtistMediation, updateArtisMediation } from '../../services/mediation';
import { checkPermission, FUNCTIONS, MODELS } from '../../utils';
import { useAuth } from "../../AppContext/Context";

const MediacaoAvaliacoes = () => {
  const { user: { is_type } } = useAuth();
  const history = useHistory();

  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [hideLoading, setHideLoading] = useState('');
  const [mediation, setMediation] = useState([]);

  useEffect(() => {
    loadMediation();
  }, [currentPage])

  async function loadMediation() {
    setLoading(true);
    const response = await getArtistMediation(currentPage);
    setLoading(false);
    if (response) {
      const totalPages = Math.ceil(response.count / itensPerPage);
      setTotalPages(totalPages);
      setMediation(response.results);
    }
  }

  async function handleHideMediation(item) {

    setHideLoading(item.id);
    const response = await updateArtisMediation(item.id, { hide: !item.hide });
    setHideLoading('');
    if (response) {
      loadMediation();
    }
  }

  function handleNavigation(event) {
    event.preventDefault()
    history.push("/criar-avaliacao/artista")
  }

  function handlePageClick(ev) {
    setCurrentPage(ev.selected + 1)
  }

  return (
    <>
      <h4><b>Mediação das avaliações Artista</b></h4>
      <Container>
        <ButtonArea>
          {checkPermission(is_type, MODELS.AvaliacaoArtista, FUNCTIONS.Inserir) ?
            <Button onClick={handleNavigation}>Criar Avaliação</Button> : null
          }
        </ButtonArea>
        {loading ?
          <LoadingSpinner /> :
          <TabelaAvaliacoes
            canEdit={checkPermission(is_type, MODELS.AvaliacaoArtista, FUNCTIONS.Atualizar)}
            handleHideMediation={handleHideMediation}
            path={'artista'}
            loading={hideLoading}
            mediation={mediation} />}
        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Próximo'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page'}
          previousClassName={'page'}
          nextClassName={'page'} />
      </Container>
    </>
  )
}

export default MediacaoAvaliacoes;