import styled from "styled-components";

export const Container = styled.div`
  width: 96%;
  background-color: #FFF;
  margin: 50px 0 50px 0;
  padding: 20px;
`;

export const Title = styled.p`
  font-size: 22px;
  font-weight: 600;
`;

export const RowCards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardBody = styled.div`
  padding: 10px 50px;
`;

export const CardContainer = styled.div`
  background-color: #10DCD41A;
  width: 285px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const CardTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const CardLabel = styled.div`
  label {
    font-weight: 600;
    margin-top: 0;
  }
  span {
    color: #828282;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 20px
`;

export const ButtonCard = styled.button`
  background-color: ${props => props.gray ? '#7E84A3' : '#D5D7E3'};
  width: 110px;
  height: 35px;
  border: none;
  border-radius: 12px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
`;

export const CharacterContainer = styled.div`
  div {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ButtonCharacter = styled.button`
  background: ${props => props.selected ? "#86fefa" : "#fff"};
  border-radius: 15px;
  border: ${props => props.selected ? "1px solid #82828200" : "1px solid #828282"};
  color: #828282;
  padding: 2px 5px;
  margin-right: 7px;
  margin-bottom: 5px;
`

export const Hr = styled.hr`
  color: #828282 !important;
  height: 1px !important;
  margin-bottom: 10px;
`

export const Button = styled.button`
  background-color: #86fefa;
  border-radius: 10px;
  border: none;
  text-transform: uppercase;
  transition: .1s;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
`;

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000031;
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  text-align: center;

  div {
    margin-bottom: 20px
  }
`;

export const ModalButton = styled.button`
  background: ${props => props.blue ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.red ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  height: 37px;
  font-weight: 600;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;
`