import styled from 'styled-components'; 

export const HeaderArea = styled.nav`
  height: 68px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: rgba(0,0,0,0.05);
`
export const Img = styled.img`
  margin: 0.8% 0 0 1.5%;
`