import styled from 'styled-components'; 


export const TableCustom = styled.table`
  background: #FFFFFF;
  box-shadow: 0px 1px 4px #15223214;
  border-left: 15px;
  border-right: 15px;
  border-top: 8px;
  border-style: solid;
  border-color: #fff;
  border-radius: 6px;
  margin-top: 50px; 
  opacity: 1;
  width: ${props => props.large ? "95%" : "auto"};

  h1{
    color: var(--unnamed-color-131523);
  }

  thead{
    border-radius: 5px;
    margin: 10px 10px;
    text-align: center;
  }
  
  th{
    background: var(--unnamed-color-f5f6fa) 0% 0% no-repeat padding-box !important;
    color: var(--unnamed-color-828282);
    letter-spacing: var(--unnamed-character-spacing-0);
    opacity: 1;  
    padding: 12px 0px 15px 0px;
  }

  td{
    color: var(--unnamed-color-828282);
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 8px;
    text-align: center;
  }
`

export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : "#DC1076 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 0 7px;
`

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000031;
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  text-align: center;

  div {
    margin-bottom: 20px
  }
`;

export const ModalButton = styled.button`
  background: ${props => props.blue ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.red ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  height: 37px;
  font-weight: 600;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;
`