import React, { useEffect, useState } from 'react'
// import { 
//   Container, Form, Label, InputTextClass, Button,
//   InputsArea, DescricaoAreaClass, UpDivs, InputDescricao 
// } from '../AvaliacoesArtistas/CriarAvaliacao/styles'
import {
  Container, Form, Label, InputTextClass, Button, RowInput,
  InputsArea, DescricaoAreaClass, UpDivs, InputDescricao
} from './styles';
import { useHistory } from 'react-router-dom'
import { updateClassVideo } from '../../services/class';

const EditarAula = ({ location }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(location.state.id);
  const [payload, setPayload] = useState({
    title: location.state.title,
    subtitle: location.state.subtitle,
    link: `https://www.youtube.com/watch?v=${location.state.link}`,
    order: isNaN(location.state.order) ? 0 : location.state.order,
    description: location.state.description
  });

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();
        
    const url = new URL(payload.link);
    const link = new URLSearchParams(url.search).get("v");

    const _payload = { ...payload, link: link }

    setLoading(true);
    const response = await updateClassVideo(id, _payload);
    setLoading(false);
    if (response) {
      history.push("/aulas/gestao")
    }
  }

  return (
    <>
      <h4><b>Nova Aula</b></h4>
      <Container>
        <Form onSubmit={handleSubmit}>
          <UpDivs>
            <InputsArea>
              <Label>
                Título da Aula
              </Label> <br />
              <InputTextClass
                required
                value={payload.title}
                onChange={(event) => handleChange("title", event.target.value)}
                placeholder="Conhecendo a Plataforma">
              </InputTextClass> <br />
              <Label>
                Subtítulo da Aula
              </Label> <br />
              <InputTextClass
                required
                value={payload.subtitle}
                onChange={(event) => handleChange("subtitle", event.target.value)}
                placeholder="aula sobre a plataforma">
              </InputTextClass> <br />
              <Label>
                Link da Aula
              </Label> <br />
              <InputTextClass
                required
                value={payload.link}
                onChange={(event) => handleChange("link", event.target.value)}
                placeholder="https://www.youtube.com/watch?v=djauebaksjh">
              </InputTextClass>
              <RowInput>
                <Label noMargin>Posição</Label>
                <InputTextClass
                  small
                  type="number"
                  required
                  value={payload.order}
                  onChange={(event) => handleChange("order", event.target.value)} />
              </RowInput>
            </InputsArea>
            {loading ?
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div> : <Button type="submit">Salvar Aula</Button>
            }
          </UpDivs>
          <DescricaoAreaClass>
            <Label>
              Descrição
            </Label> <br />
            <InputDescricao
              required
              value={payload.description}
              onChange={(event) => handleChange("description", event.target.value)}
              placeholder="Conhecendo a Plataforma">
            </InputDescricao>
          </DescricaoAreaClass>
        </Form>

      </Container>
    </>
  )
}

export default EditarAula;