import styled from 'styled-components';
import CurrencyInput from 'react-currency-input';

export const FormCustom = styled.form`
  background: #ffffff;
  box-shadow: 0px 1px 4px #15223214;
  opacity: 1;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-right: 30px;
  padding: 20px;
  width: auto;
  height: 95%;

  label{
    font-size: 18px;
  }

`
export const SelectRecurrency = styled.select`
  width: auto !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`;

export const Hr = styled.hr`
  color: #828282 !important;
  height: 1px !important;
  margin-bottom: 10px;
`

export const InputName = styled.input`
  width: 280px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #828282;
  margin-right: 30px;
  padding: 7px;
`

export const InpuCustom = styled.input`
  width: 180px;
  height: 40px;
  border: 1px solid #828282;
  border-radius: 10px;
  padding: 7px; 
`

export const CustomCurrencyInput = styled(CurrencyInput)`
  width: 180px;
  height: 40px;
  border: 1px solid #828282;
  border-radius: 10px;
  padding: 7px; 
`;

export const SelectGender = styled.select`
  width: auto !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`

export const SelectDisabled = styled.select`
  width: auto !important; 
  height: 44px;
  border: 1px solid;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`;

export const ElemtDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10px;
  margin-bottom: 6px;

  div{
    width: auto;
  }

  select{
    height: 40px;
    width: 60px;
    border-radius: 10px;
    margin-right: 5px;
  }

  .input{
    width: 180px;
    height: 50px;
    border-radius: 10px;
    padding: 7px;
  }
`

export const DivCustom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;
`

export const DivLeft = styled.div`
  display: flex;
  flex-direction: column;
`

export const DivCentral = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const DivRight = styled.div`
  display: flex;
  flex-direction: column;
`

export const ElemtDivCabelo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 90%;

  div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  
`

export const ButtonCabelo = styled.button`
  background: ${props => props.selected ? "#86fefa" : "#fff"};
  border-radius: 15px;
  border: ${props => props.selected ? "1px solid #82828200" : "1px solid #828282"};
  color: #828282;
  padding: 2px 5px;
  margin-right: 7px;
  margin-bottom: 5px;

`

export const ElemtDivOlhos = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  width: 90%;
  margin-bottom: 0px;
`
export const ElemtDivIdade = styled.div`
  display: contents;
  float: right;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const ElementDivAltura = styled.div`
  // margin-top: 36px;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const ElementDivCintura = styled.div`
  margin-top: 36px;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const ElementDivQuadril = styled.div`
    margin-left: 20px;
    margin-top: 10px;

    p{
      font-size: 12px;
      color: #828282;
      margin-bottom: 0;
    }
`

export const ElementDivBusto = styled.div`
  margin-top: 35px;
  margin-left: 20px;

  p{
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
`

export const DivFotos = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;

  div {
    display: grid;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  span {
    background-color: #e6e2e2;
    text-align: center;
  }

  img{
    border-radius: 6px;
    width: 150px;
    height: 250px;
    margin-right: 15px;
    object-fit: contain;
  }
`

export const ButtonAddFoto = styled.button`
  background: #cecece;
  border-radius: 6px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 250px;
  margin-right: 15px;

  img{
    width: 30px;
    height: auto;
    margin-bottom: 30px;
  }
`

export const ButtonSalvar = styled.button`
  background: #86fefa;
  border: none;
  border-radius: 10px;
  display: flex;
  margin-top: -15px;
  margin-left: auto;
  padding: 10px;
`

export const CardCustom = styled.figcaption`
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 6px;
  float: left;
  margin-right: 10px;
  width: 135px;
  margin-bottom: 10px;
  position: relative;

  button{
    border: none;
    border-radius: 20px;
    background-color: red;
    color: #fff;
    font-weight: 600;
    width: 25px;
    height: 25px;
    right: 0;
    position: absolute;
  }

  img{
    border-radius: 6px;
    width: 133px;
    height: 200px;
  }

  input{
    width: 20px;
    height: 20px;
  }

  label{
    font-size: 12px;
    float: right;
    margin-left: 5px;
  }
`