const currentDomain = window.location.href;

export let BASE_URL_DEV = '';

if(currentDomain.includes('app.goodcasting')) {
    BASE_URL_DEV = 'https://api.goodcasting.pt/api/v1/';
} else if(currentDomain.includes('devaribox.co')) {
    BASE_URL_DEV = 'https://goodcasting-api.devaribox.co/api/v1/';
} else if(currentDomain.includes('localhost')) {
    //change this if needed for local development
    BASE_URL_DEV = 'https://goodcasting-api.devaribox.co/api/v1/';
    // BASE_URL_DEV = 'http://localhost:8000/api/v1/';
    // BASE_URL_DEV = 'https://api.goodcasting.pt/api/v1/';
}

export const SESSION_URL = `${BASE_URL_DEV}session/logged/`;

export const AUTH_URL = `${BASE_URL_DEV}admin/authentication/`; 
export const ADMIN_URL = `${BASE_URL_DEV}user/admin/`;

export const CLIENT_SOLICITATION = `${BASE_URL_DEV}job/solicitation/`;

export const GET_URL_ARTISTA = `${BASE_URL_DEV}user/artist/`;
export const APPROVED_ARTIST_URL = `${BASE_URL_DEV}user/artist/approved/`;
export const ARTIST_REJECT = `${BASE_URL_DEV}user/artist/rejected/`;
export const ARTIST_PHOTO = `${BASE_URL_DEV}image/photo/`;

export const PROFILE_URL = `${BASE_URL_DEV}job/profile/`;

export const GET_URL_CLIENTE = `${BASE_URL_DEV}user/client/`;
export const POST_URL_INVITE = `${BASE_URL_DEV}user/client/invite/`;
export const CLIENT_REJECT = `${BASE_URL_DEV}user/client/rejected/`;
export const CLIENT_APPROVED = `${BASE_URL_DEV}user/client/approved/`;

export const MEDIATION_ARTIST_URL = `${BASE_URL_DEV}user/evaluation/artist/`;
export const MEDIATION_PRODUCER_URL = `${BASE_URL_DEV}user/evaluation/client/`;

export const JOB_SOLICITATION_URL = `${BASE_URL_DEV}job/solicitation/`;

export const CLASS_URL = `${BASE_URL_DEV}video/class/`;

export const NOTIFICATION_URL = `${BASE_URL_DEV}support/notification/`;

export const PERMISSION_URL = `${BASE_URL_DEV}permission/list/`;

export const TYPES_URL = `${BASE_URL_DEV}permission/type/`;

export const RECURRING_PAYMENT_TYPE = `${BASE_URL_DEV}recurring-payment-type/`;

export const UPLOAD_PHOTO = `https://api-erp.devaribox.co/api/v1/aws/s3-goodcasting`;
