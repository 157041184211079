import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useAuth } from './AppContext/Context';

import Header from './components/Navbar';
import Login from './pages/Login';
import CreateGlobalStyle from './global'
import MenuSidebar from './components/MenuLateral';
import routes from './routes';
import './App.css'

function App() {
  const { user } = useAuth();

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route {...rest} render={props =>
      (
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      )} />)
  }
  const PublicRoute = ({ component: Component, ...rest }) => {
    return (
      <Route {...rest} render={props =>
      (
        user ? (
          <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      )} />)
  }

  return (
    <div className="App">
      <CreateGlobalStyle />
      <HashRouter>
        <CreateGlobalStyle />
        <Switch>

          <PublicRoute exact path="/" component={Login} />
          {/* <PublicRoute exact path="/forgotpassword" component={ForgotPassword} /> */}
          <>
            <Header />
            <main className="mainContent">
              <MenuSidebar />
              <div className="content">
                <Switch>
                  
                  {routes.map(item => (
                    <PrivateRoute exact path={item.path} component={item.component} />
                  ))}

                </Switch>
              </div>
            </main>
          </>
          <Redirect to={{ pathname: '/' }} />

        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
