import React, { useState, useEffect } from 'react'
import {
  Container, Form, Label,
  Button, AvaliacaoSpace,
  Function, SelectFunction, InputStar,
  LabelStar, DescricaoArea, UpDivs, InputDescricao
} from './styles'
import { FaStar } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { GetListaArtista } from '../../../services/artist';
import { createProducerMediation, GetProducers } from '../../../services/mediation';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, SUCCESS, ERROR } from '../../../components/Notifications';

const CriarAvaliacao = () => {
  const history = useHistory()

  const [hover, setHover] = useState(null);
  const [artists, setArtists] = useState([]);
  const [producers, setProducers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    grade: 5,
    evaluator: '',
    rated: '',
    description: '',
    tag: []
  })

  useEffect(() => {
    loadArtists();
    loadProducers();
  }, [])

  async function loadArtists() {
    const response = await GetListaArtista();
    if (response) {
      setArtists(response.data);
    }
  }

  async function loadProducers() {
    const response = await GetProducers();
    if (response) {
      setProducers(response.data);
    }
  }

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const response = await createProducerMediation(payload);
    setLoading(false);
    if (response) {
      history.push("/mediacao/avaliacoes/produtores");
      addToast(SUCCESS, 'Avaliação criada com sucesso');
    } else {
      addToast(ERROR, 'Não foi possível enviar essa avaliação');
    }
  }

  return (
    <>
      <h4><b>Criar Avaliação Produtor</b></h4>
      <Container>
        <Form onSubmit={handleSubmit}>
          <UpDivs>
            <div className="d-flex">

              <AvaliacaoSpace>
                <Label>
                  Produtor Avaliado
                </Label> <br />
                <SelectFunction
                  required
                  onChange={({ target }) => handleChange('rated', Number(target.value))}>
                  <option hidden defaultChecked>Escolha um Produtor</option>
                  {producers.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </SelectFunction> <br />

                <Label>
                  Artista Avaliador
                </Label> <br />
                <SelectFunction
                  required
                  onChange={({ target }) => handleChange('evaluator', Number(target.value))}>
                  <option hidden defaultChecked>Escolha um Artista</option>
                  {artists.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </SelectFunction>
              </AvaliacaoSpace>

              <Function id="alvo">
                <Label>
                  Avaliação <br />
                  {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;
                    return (
                      <LabelStar>
                        <InputStar
                          type="radio"
                          name="rating"
                          onClick={() => handleChange('grade', Number(ratingValue))}

                        />
                        <FaStar className="star"
                          color={ratingValue <= (hover || payload.grade) ? "#86fefa" : "#e4e5e9"}
                          size={26}
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)} />
                      </LabelStar>
                    )
                  })}
                </Label>
              </Function>
            </div>
            {loading ? <LoadingSpinner /> :
              <Button type="submit">Criar avaliação</Button>}
          </UpDivs>
          <DescricaoArea>
            <Label>
              Descrição
            </Label> <br />
            <InputDescricao
              required
              onChange={({ target }) => handleChange('description', target.value)}>
            </InputDescricao>
          </DescricaoArea>
        </Form>
      </Container>

    </>
  )
}

export default CriarAvaliacao;