import styled from "styled-components";

export const Container = styled.div`
  width: 96%;
  background-color: #FFF;
  margin: 50px 0 50px 0;
  padding: 10px;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Input = styled.input`
  max-width: 55%;
  height: 44px;
  padding: 0 0 0 10px;
  border-radius: 8px;
  border: 1px solid #828282;
`;

export const InputTextarea = styled.textarea`
  max-width: 55%;
  height: 44px;
  margin: 8px 0 0 4px;
  padding: 4px 0 0 10px;
  border-radius: 8px;
`;

export const RowInputs = styled.div`
  display: flex;

  div {
    margin-right: 10px;
  }

  input {
    min-width: 150px;
  }
`;

export const Select = styled.select`
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`;

export const Button = styled.button`
  background-color: ${(props) => props.loading ? "#707070" : "#86fefa"};
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 22px 34px 0 auto;
`
