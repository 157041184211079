import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";

import {
  DeleteArtist,
  GetPaginatedArtists,
  GetSearchArtists,
} from "../../services/artist";
import {
  Content,
  TableCustom,
  ButtonEdit,
  ButtonSearch,
  ModalButton,
  ModalContainer,
  ModalBody,
  SearchContainer,
  Row,
} from "./styles";
import LoadingSpinner from "../LoadingSpinner";
import Notification, { ERROR, SUCCESS } from "../Notifications";
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import { useAuth } from "../../AppContext/Context";

function Table() {
  const {
    user: { is_type },
  } = useAuth();
  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [load, setLoad] = useState(false);

  const [modal, setModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const [searchList, setSearchList] = useState([]);
  const [isSearch, setIsSearch] = useState(0);
  const [searchParams, setSearchParam] = useState({
    name: "",
    email: "",
    phone: "",
    nick_name: "",
  });

  let history = useHistory();

  const [artists, setArtists] = useState([]);

  useEffect(() => {
    listaArtista();
  }, [currentPage]);

  function handleClick(profile) {
    history.push({
      pathname: "/artista/editar-perfil",
      state: {
        profile,
      },
    });
  }

  async function listaArtista() {
    setLoad(true);
    const resp = await GetPaginatedArtists(currentPage);
    if (resp) {
      setArtists(resp.data.results);
      setLoad(false);
      const totalPages = Math.ceil(resp.data.count / itensPerPage);
      setPages(totalPages);
    } else {
      setLoad(false);
      Notification("Nenhum dado retornado", "", "error");
    }
  }

  function handlePageClick(ev) {
    setCurrentPage(ev.selected + 1);
  }

  async function handleDelete() {
    setLoadingModal(true);
    const resp = await DeleteArtist(modal);
    setLoadingModal(false);
    if (resp) {
      listaArtista();
      Notification("Usuário apagado", "", SUCCESS);
      setModal(null);
    } else {
      Notification("Não foi possível apagar usuário", "", ERROR);
    }
  }

  async function submitSearch() {
    if (
      searchParams.name.length > 3 ||
      searchParams.email.length > 3 ||
      searchParams.phone.length > 3 ||
      searchParams.nick_name.length > 1
    ) {
      setIsSearch(1);
      const resp = await GetSearchArtists(searchParams);
      setIsSearch(2);
      if (resp) {
        setSearchList(resp);
      }
    } else {
      Notification("Informe no mínimo 4 caracteres", "", "error");
    }
  }

  function handleKey(e) {
    if (e.key === "Enter" && isSearch !== 1) {
      submitSearch();
    }
  }

  function handleChangeSearch(field, value) {
    setSearchParam({ ...searchParams, [field]: value });
  }

  function clearFilter() {
    setIsSearch(0);
    setSearchList([]);
    setSearchParam({ name: "", email: "", phone: "", nick_name: "" });
  }

  return (
    <div>
      <h2>Visualização de Perfil</h2>

      <Content>
        <SearchContainer>
          <Row>
            <div>
              <label>Apelido: </label>
              <input
                onKeyDown={handleKey}
                value={searchParams.nick_name}
                onChange={({ target }) =>
                  handleChangeSearch("nick_name", target.value)
                }
              />
            </div>
            <div>
              <label>Nome: </label>
              <input
                onKeyDown={handleKey}
                value={searchParams.name}
                onChange={({ target }) =>
                  handleChangeSearch("name", target.value)
                }
              />
            </div>
            <div>
              <label>Email: </label>
              <input
                onKeyDown={handleKey}
                value={searchParams.email}
                onChange={({ target }) =>
                  handleChangeSearch("email", target.value)
                }
              />
            </div>
          </Row>
        </SearchContainer>

        <SearchContainer>
          <Row>
            <div>
              <label>Telefone: </label>
              <input
                type="number"
                onKeyDown={handleKey}
                value={searchParams.phone}
                onChange={({ target }) =>
                  handleChangeSearch("phone", target.value)
                }
              />
            </div>
            <div>
              <ButtonSearch azul large onClick={() => submitSearch()}>
                Procurar
              </ButtonSearch>
              <ButtonSearch onClick={() => clearFilter()}>Limpar</ButtonSearch>
            </div>
          </Row>
        </SearchContainer>
        <TableCustom large>
          <thead>
            <th>Nome</th>
            <th>Email</th>
            <th>Telefone</th>
            <th>Status</th>
            <th>Funções</th>
          </thead>
          <tbody>
            {load === true ? (
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            ) : isSearch != 0 ? (
              isSearch === 1 ? (
                <div className="text-center m-5">
                  <div className="spinner-border text-info" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                searchList.map((profile, index) => (
                  <tr key={index}>
                    <td>{profile.name}</td>
                    <td>{profile.email}</td>
                    <td>{profile.phone}</td>
                    <td>{profile.status}</td>
                    <td>
                      {checkPermission(
                        is_type,
                        MODELS.Artista,
                        FUNCTIONS.Atualizar
                      ) ? (
                        <>
                          <ButtonEdit
                            azul
                            large
                            font12
                            onClick={() => handleClick(profile)}
                          >
                            EDITAR PERFIL
                          </ButtonEdit>
                          <ButtonEdit
                            verm
                            large
                            font12
                            onClick={() => setModal(profile.id)}
                          >
                            APAGAR PERFIL
                          </ButtonEdit>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))
              )
            ) : (
              artists.map((profile, index) => (
                <tr key={index}>
                  <td>{profile.name}</td>
                  <td>{profile.email}</td>
                  <td>{profile.phone}</td>
                  <td>{profile.status}</td>
                  <td>
                    {checkPermission(
                      is_type,
                      MODELS.Artista,
                      FUNCTIONS.Atualizar
                    ) ? (
                      <>
                        <ButtonEdit
                          azul
                          large
                          font12
                          onClick={() => handleClick(profile)}
                        >
                          EDITAR PERFIL
                        </ButtonEdit>
                        <ButtonEdit
                          verm
                          large
                          font12
                          onClick={() => setModal(profile.id)}
                        >
                          APAGAR PERFIL
                        </ButtonEdit>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </TableCustom>
        {isSearch !== 0 ? null : (
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page"}
            previousClassName={"page"}
            nextClassName={"page"}
          />
        )}
      </Content>
      {modal && (
        <ModalContainer>
          <ModalBody>
            <div>
              <h2>Tem certeza que deseja excluir o Usuário?</h2>
            </div>
            {loadingModal ? (
              <LoadingSpinner noMargin={true} />
            ) : (
              <>
                <ModalButton blue onClick={handleDelete}>
                  EXCLUIR
                </ModalButton>
                <ModalButton onClick={() => setModal(null)}>
                  CANCELAR
                </ModalButton>
              </>
            )}
          </ModalBody>
        </ModalContainer>
      )}
    </div>
  );
}

export default Table;
