import styled from "styled-components";

export const ButtonArea = styled.div`
  width: 96%;
  display: flex;
`

// height: 400px; tamanho pre-definido no container
export const Container = styled.div`
  width: 96%;
  background-color: #FFF;
  margin-top: 2%;
  padding-top: 8px;
`


export const Button = styled.button`
  background-color: #86fefa;
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: auto;
  `

export const ButtonVideoOff = styled.button`
  background-color: ${props => props.red ? "#DC1076" : props.is_active ? "#828282" : "#86fefa"};
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1% 1% 2% auto;
  color: ${props => props.red ? "#fff" : "initial"};
`

export const ButtonsContainer = styled.div`
  text-align: center;
`

export const VideoArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1% 0 0 2%;

  span{
    margin: 1% 0 1% 0;
    font-weight: bold;
  }
` 

export const VideoAreaIndividual = styled.div`
  display: flex;
  flex-direction: row;
  height: 150px;
  margin: 0 0 30px 0;
`
export const VideoDescription = styled.div`
  font-size: 14px;
  display: block;
  height: 100%;
  width: 60%;
  margin: 0 2% 0 1%;

  h5{
    font-weight: bold;
    cursor: pointer;
    color: #86fefa;
  } 
`

export const DivMain = styled.div`
  position: absolute; 
  z-index: 2;
`

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000031;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

`

export const ModalContainer = styled.div`
  width: 40%;
  height: 34%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: absolute;
  z-index: 4;
`

export const DivTitle = styled.div`
  display: inline-block;
  letter-spacing: 0px;
  color: #131523;
  opacity: 1;
  text-align: left;

  h3{
    font-weight: bold;
    text-align: left;
  }
`

export const Body = styled.div`
  color: #000;
  flex: 50%;
  text-align: left;

  label{
    margin-top: 25px;
    font-size: 18px;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 1%;
`

export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : "#DC1076 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 0 7px;
`