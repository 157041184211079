import styled from 'styled-components'; 


export const TableCustom = styled.table`
  background: #FFFFFF;
  box-shadow: 0px 1px 4px #15223214;
  border-left: 15px;
  border-right: 15px;
  border-top: 8px;
  border-style: solid;
  border-color: #fff;
  border-radius: 6px;
  margin-top: 2%;
  opacity: 1;
  width: ${props => props.large ? "98%" : "auto"};

  h1{
    color: var(--unnamed-color-131523);
  }

  thead{
    border-radius: 5px;
    margin: 10px 10px;
    text-align: center;
  }
  
  th{
    background: var(--unnamed-color-f5f6fa) 0% 0% no-repeat padding-box !important;
    color: var(--unnamed-color-828282);
    letter-spacing: var(--unnamed-character-spacing-0);
    opacity: 1;  
    padding: 16px 18px 12px 0;
  }

  td{
    color: var(--unnamed-color-828282);
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 4px;
    padding-left: 10px;
    text-align: left;
  }
`

export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : "#DC1076 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  width: 128px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 18px;
  margin-right: 0;
  padding: 0 7px;
`