import axios from 'axios';
import { NOTIFICATION_URL } from './config';

export const getNotifications = async (page) => {
  const params = { page, function__in: "Modelo, Produtor" };
  try {
    const response = await axios.get(NOTIFICATION_URL, { params })
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const createNotification = async (payload) => {
  try {
    const response = await axios.post(NOTIFICATION_URL, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const updateNotification = async (payload, id) => {
  try {
    const response = await axios.patch(`${NOTIFICATION_URL}${id}/`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}