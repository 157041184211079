import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Container,
	Content,
	Information,
	InformationRow,
	Header,
	HeaderContent,
	Field,
	Label,
	Value,
	Photo,
	JobHeader,
	JobTitle,
	JobDescription
} from './styles';
import {
	ApprovedClient,
	RejectClient,
	GetClientJobs
} from '../../../services/client';
import { ERROR, SUCCESS, addToast } from '../../../components/Notifications';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal/Modal';

function ProfileApproval() {
	const location = useLocation();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [jobLoad, setJobLoad] = useState(false);
	const [jobs, setJobs] = useState([]);
	const [modal, setModal] = useState(false);
	const [payload, setPayload] = useState({
		reason: '',
		description: ''
	});

	const [user, setUser] = useState({
		id: '',
		name: '',
		email: '',
		phone: '',
		taxpayer: '',
		address: '',
		number: '',
		status: '',
		address_city: '',
		address_state: '',
		full_address: '',
		site: '',
		trading_name: '',
	});

	useEffect(() => {
		if (!location.state) {
			return history.push('/clientes/aprovacao');
		}

		const profile = location.state.profile;
		loadJobs(profile.id);
		setUser({ ...profile });
	}, [location]);

	async function loadJobs(clientId) {
		setJobLoad(true);
		const resp = await GetClientJobs(clientId);
		setJobLoad(false);
		if (resp) {

			setJobs(resp);
		}
	}

	const handleTriggerModal = (user) => {
		setModal(user);
		setPayload({
			reason: '',
			description: ''
		})
	}

	function handleChangeReject(name, value) {
		setPayload({ ...payload, [name]: value });
	}

	async function handleApproval() {
		const resp = await ApprovedClient({ client: user.id });
		if (resp && resp.status === 200) {
			addToast(SUCCESS, "Cliente aprovado com sucesso!");
			history.push("/clientes/aprovacao");
		} else {
			addToast(ERROR, "Não foi possível realizar essa operação");
		}
	}

	async function rejectModal() {
		if (validForm()) {
			setLoading(true);
			const resp = await RejectClient({ ...payload, client: modal.id });
			setLoading(false);
			if (resp && resp.status === 200) {
				addToast(SUCCESS, "Cliente cancelado com sucesso!");
				handleTriggerModal(false);
				history.push('/clientes/aprovacao');
			} else {
				addToast(ERROR, "Não foi possível realizar essa operação");
			}
		}
	}

	function validForm() {
		if (!payload.reason || !payload.description) {
			addToast(ERROR, 'Informe o Motivo da rejeição');
			return false;
		}
		return true;
	}

	return (
		<>
			<Container>
				<h2>Aprovação de Perfil</h2>
				<Content>
					<Header>
						<HeaderContent>
							<p>{user.name}</p>
						</HeaderContent>
						<HeaderContent>
							<Button onClick={handleApproval}>Aprovar Cadastro</Button>
							<Button onClick={() => handleTriggerModal(user)}>Rejeitar Cadastro</Button>
						</HeaderContent>
					</Header>
					<Information>
						<InformationRow>
							<Field>
								<Label>Email</Label>
								<Value>{user.email}</Value>
							</Field>
							<Field>
								<Label>Telefone</Label>
								<Value>{user.phone}</Value>
							</Field>
							<Field>
								<Label>NIF/NIPC</Label>
								<Value>{user.taxpayer}</Value>
							</Field>
						</InformationRow>
						<InformationRow>
							<Field>
								<Label>Endereço</Label>
								<Value>{user.full_address}</Value>
							</Field>
						</InformationRow>
						<InformationRow>
							<Field>
								<Label>Cidade/Estado</Label>
								<Value>{user.address_city}, {user.address_state}</Value>
							</Field>
						</InformationRow>
						<InformationRow>
							<Field>
								<Label>Site</Label>
								<Value>{user.site}</Value>
							</Field>
						</InformationRow>
						<InformationRow>
							<Field>
								<Label>Nome Fantasia(opcional)</Label>
								<Value>{user.trading_name}</Value>
							</Field>
						</InformationRow>
						<Photo src={user.image} />
					</Information>
				</Content>
				{modal && <Modal
					loading={loading} closeModal={handleTriggerModal}
					rejectModal={rejectModal} handleChangeReject={handleChangeReject} />}
			</Container>
			<h2>Job Cadastrado</h2>
			{jobLoad ?
				<div className="text-center m-5">
					<div className="spinner-border text-info" role="status">
						<span class="visually-hidden"></span>
					</div>
				</div>
				:
				jobs.map((job) => (
					<Container key={job.id}>
						<Content>
							<JobHeader>
								<JobTitle>{job.title}</JobTitle>
								<JobDescription>{job.description}</JobDescription>
							</JobHeader>
							<Information>
								<InformationRow>
									<Field>
										<Label>Horário</Label>
										<Value>{job.time}</Value>
									</Field>
									<Field>
										<Label>Data</Label>
										<Value>{job.date}</Value>
									</Field>
									<Field>
										<Label>Cache</Label>
										<Value>{job.value}</Value>
									</Field>
								</InformationRow>
								<InformationRow>
									<Field>
										<Label>Transporte</Label>
										<Value>{job.transport}</Value>
									</Field>
									<Field>
										<Label>Alimentação</Label>
										<Value>{job.feeding}</Value>
									</Field>
									<Field>
										<Label>Perfis</Label>
										<Value>{job.profile.name}</Value>
									</Field>
								</InformationRow>
								<InformationRow>
									<Field>
										<Label>Tempo de Direito de Imagem</Label>
										<Value>{job.campaign_broadcast}</Value>
									</Field>
									<Field>
										<Label>Morada</Label>
										<Value>{job.full_address}</Value>
									</Field>
								</InformationRow>
							</Information>
						</Content>
					</Container>
				))
			}
		</>
	);
}

export default ProfileApproval;