import styled from 'styled-components';

export const Container = styled.div`
  padding: 1em 3em 3em 0px;

  & > h2 {
    margin-bottom: 56px;
  }
`;

export const Content = styled.div`
  background-color: #FFF;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 1em 2em;
`;

export const Form = styled.form`
  & > textarea {
    margin-bottom: 76px;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 66px;

  & > div {
      width: 48%;
  }
`;

export const AlignEnd = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  & > button {
    height: 45px;
  }
`;