import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ContainerNotificacao, Form, Label, Button,
  DescricaoAreaClass, InputDescricao, FunctionNotify,
  SelectFunction, InputTextClass
} from './styles';
import { createNotification } from '../../../services/notification';
import { GetAllArtist } from '../../../services/artist';
import { GetAllClient } from '../../../services/client';

const EnviarNotificacao = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [payload, setPayload] = useState({
    title: '',
    function: '',
    message: ''
  });

  useEffect(() => {
    loadArtists();
    loadClients();
  }, [])

  async function loadArtists() {
    const response = await GetAllArtist();
    if (response) {
      setArtistList(response);
    }
  }

  async function loadClients() {
    const response = await GetAllClient();
    if(response) {
      setClientList(response);
    }
  }

  function handleChange(name, value) {
    if (name === "function") {
      const _newPayload = {...payload};
      delete _newPayload.artist;
      delete _newPayload.client;
      setPayload({..._newPayload, [name]: value});
    } else {
      setPayload({ ...payload, [name]: value });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const response = await createNotification(payload);
    setLoading(false);
    if (response) {
      history.push('/notificacoes')
    }
  }

  return (
    <>
      <h4><b>Cadastro de Notificação</b></h4>
      <ContainerNotificacao>
        <Form onSubmit={handleSubmit}>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}>
            Salvar Notificação
          </Button>
          <FunctionNotify id="alvo">
            <Label>Função</Label> <br />
            <SelectFunction name="function" id="function" required 
              onChange={({ target }) => handleChange('function', target.value)}>
              <option hidden>Escolha uma opção</option>
              <option value="Modelo">Modelo</option>
              <option value="Produtor">Produtores</option>
            </SelectFunction>
          </FunctionNotify>

          {payload.function === "Modelo" ? <FunctionNotify>
            <Label>Modelo</Label> <br />
            <SelectFunction name="artist" id="artist" required
              onChange={({ target }) => handleChange('artist', Number(target.value))}>
              <option hidden>Todos</option>
              {artistList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </SelectFunction>
          </FunctionNotify> : null }

          {payload.function === "Produtor" ? <FunctionNotify>
            <Label>Produtor</Label> <br />
            <SelectFunction name="client" id="client" required
              onChange={({ target }) => handleChange('client', Number(target.value))}>
              <option hidden>Todos</option>
              {clientList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </SelectFunction>
          </FunctionNotify> : null }

          <div style={{ marginLeft: '2%' }}>
            <Label>
              Título
            </Label> <br />
            <InputTextClass
              required
              onChange={({ target }) => handleChange('title', target.value)}
              placeholder="Insira o título"></InputTextClass>
          </div>
          <DescricaoAreaClass>
            <Label>
              Mensagem da notificação
            </Label> <br />
            <InputDescricao
              required
              onChange={({ target }) => handleChange('message', target.value)}
              placeholder="Insera o texto que será apresentado na notificação">
            </InputDescricao>
          </DescricaoAreaClass>
        </Form>
      </ContainerNotificacao>
    </>
  )
}

export default EnviarNotificacao;