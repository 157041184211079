import React, { useEffect, useState } from 'react';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, ERROR, SUCCESS } from '../../../components/Notifications';
import { GetAdmins, UpdateAdmin, CreateAdmin } from '../../../services/admin';
import ModalUserAdmin from './modalUserAdmin';
import {
  Table, Button
} from './styles';
import { checkPermission, FUNCTIONS, MODELS } from "../../../utils";
import { useAuth } from "../../../AppContext/Context";

const Users = ({ types }) => {
  const { user: { is_type } } = useAuth();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);

  const [modal, setModal] = useState(false);

  useEffect(() => {
    loadUsers();
  }, [])

  async function loadUsers() {
    setLoading(true);
    const response = await GetAdmins();
    setLoading(false);
    if (response) {
      setUserList(response);
    }
  }

  async function handleDisable(user) {
    setUserLoading(user.id);
    const response = await UpdateAdmin(user.id, { is_active: !user.is_active });
    setUserLoading(false);
    if (response) {
      addToast(SUCCESS, 'Usuário atualizado com sucesso');
      loadUsers();
    } else {
      addToast(ERROR, 'Não foi possível desabilitar esse usuário');
    }
  }

  function toggleModal(item) {
    setModal(item);
    if (!item) {
      loadUsers();
    }
  }

  return (
    <>
      <h4>Lista de Usuários</h4>
      <Table large>
        <thead>
          <th>nome</th>
          <th>Email</th>
          <th>Type</th>
          <th></th>
        </thead>
        <tbody>
          {userList.map((item, index) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.is_type.name}</td>
              <td>
                {checkPermission(is_type, MODELS.Admin, FUNCTIONS.Atualizar) ?
                userLoading === item.id ? <LoadingSpinner noMargin /> :
                  <div>
                    <Button blue onClick={() => toggleModal(item)}>Editar</Button>
                    <Button
                      blue={!item.is_active}
                      onClick={() => handleDisable(item)}>
                      {item.is_active ? `Desativar` : `Ativar`}
                    </Button>
                  </div> : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mt-3">
        {checkPermission(is_type, MODELS.Admin, FUNCTIONS.Inserir) ?
          <Button blue onClick={() => toggleModal(true)}>Criar Novo</Button> : null}
      </div>

      {modal ? <ModalUserAdmin selected={modal} toggleModal={toggleModal} types={types} /> : null}
    </>
  );
}

export default Users;