import React from 'react'; 
import { DashboardTotal, ButtonMonth, CardsUp, CardsDown } from './styles';
import LineChart from '../../components/ChartsMenores';
import ChartMensalidade from '../../components/ChartMensalidade';
import ChartCrescimentoMensalidade from '../../components/ChartCrescimentoMensalidade';



const DashBoardInicial = () => {
  return (
      <>
          <h4><b>Dashboard</b></h4> 
          <DashboardTotal>
          <CardsUp>
            <LineChart />
          </CardsUp>
          <ButtonMonth>Últimos 12 meses</ButtonMonth>
          <CardsDown>
          <ChartMensalidade />
          <ChartCrescimentoMensalidade />
          </CardsDown>
        </DashboardTotal>
      </>
  )
}


export default DashBoardInicial;