import { createGlobalStyle } from 'styled-components';
export default createGlobalStyle`

* {
  margin: 0; 
  padding: 0; 
  box-sizing: border-box; 
  --unnamed-color-828282: #828282;
  --unnamed-color-8ad7ff: #8AD7FF;
  --unnamed-color-86fefa: #86fefa;
  --unnamed-color-66fff9: #66FFF9;
  --unnamed-color-adfffc: #ADFFFC;
  --unnamed-color-18efe6: #18EFE6;
  --unnamed-color-f0142f: #F0142F;
  --unnamed-color-7e84a3: #7E84A3;
  --unnamed-color-d5d7e3: #D5D7E3;
  --unnamed-color-d7dbec: #D7DBEC;
  --unnamed-color-e6e9f4: #E6E9F4;
  --unnamed-color-f5f6fa: #F5F6FA;
  --unnamed-color-212731: #212731;
  --unnamed-color-131523: #131523;
  --unnamed-color-2a2a2a: #2A2A2A;

  --unnamed-font-family-roboto: Roboto;
  --unnamed-font-family-poppins: Poppins;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-300: 300px;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-13: 13px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-21: 21px;
  --unnamed-font-size-28: 28px;
  --unnamed-font-size-37: 37px;
  --unnamed-font-size-50: 50px;
  --unnamed-font-size-55: 55px;
  --unnamed-font-size-56: 56px;
  --unnamed-font-size-67: 67px;
  --unnamed-font-size-89: 89px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-0-12: 0.12px;
  --unnamed-line-spacing-12: 12px;
  --unnamed-line-spacing-16: 16px;
  --unnamed-line-spacing-18: 18px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-25: 25px;
  --unnamed-line-spacing-39: 39px;
  --unnamed-line-spacing-42: 42px;
  --unnamed-line-spacing-44: 44px;
  --unnamed-line-spacing-60: 60px;
  --unnamed-line-spacing-73: 73px;
  --unnamed-line-spacing-74: 74px;
  --unnamed-line-spacing-97: 97px;
  --unnamed-line-spacing-117: 117px;

}

body {
  margin: 0; 
  padding: 0; 
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}
`