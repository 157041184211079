import axios from 'axios';
import {UPLOAD_PHOTO} from './config';

export const uploadArtistPhoto = async (photos) => {
    try {
        const response = await axios.post(`${UPLOAD_PHOTO}/testes/devariupload/`, photos, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}