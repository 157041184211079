import React, { useState, } from 'react';
import { 
  LoginStyled, Form, Imagembackground,
  Input, InputText, Label, Logo, ButtonGetIn, 
  FirstColumn, FormText, Footer, LabelCheckBox,
  ForgotPasswordLink, SecondColumn 
} from './styles';
import Images from '../../assets';
import { authentication } from '../../services/auth';
import { useAuth } from '../../AppContext/Context';
import { addToast, ERROR, SUCCESS } from '../../components/Notifications';
import { BASE_URL_DEV } from '../../services/config';

const Login = () => {
  const { handleUser, load } = useAuth();

  const [loading, setLoading] = useState(false);
  const [keep, setKeep] = useState(false); 
  const [payload, setPayload] = useState ({
    username: '', 
    password: '', 
  })

  var style = {
    textDecoration: 'none',
    color: '#828282',
  };

  const handleChange = (name, value) => {
    setPayload ({
      ...payload,
      [name]:value,  
    }); 
  }

  const handleLogged = () => {
    setKeep(!keep);
  }
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isLogged = await authentication(payload);
    setLoading(false);
    if (isLogged) { 
      addToast(SUCCESS, 'Login Efetuado');
      handleUser(isLogged, keep); 
    } else {
      addToast(ERROR, 'Email ou Senha inválidos');
    }
  } 
  
  return (
    load ? 
    <LoginStyled>
      <FirstColumn>
        <Form>
        <Logo src={Images.logo.default}  alt="logo">
        </Logo> <br /> 
          <FormText>
              Para seu job ser postado, entre ou faça seu cadastro
          </FormText>
            <Label for="exampleInputEmail1" className="form-label">Email</Label>
            <InputText
              name="username"
              type="email" 
              className="form-control"  
              placeholder="seunome@email.com"
              onChange={(e) => handleChange('username', e.target.value)}
              />
            <Label for="exampleInputPassword1" class="form-label">Senha</Label>
            <InputText 
              name="password"
              type="password" 
              className="form-control"  
              placeholder="Digite sua senha"
              onChange={(e) => handleChange('password',e.target.value)}
              />
            <ForgotPasswordLink>
               <a href={`${BASE_URL_DEV}accounts/password_reset/`} target="_blank" style={style}> Esqueci minha senha</a>
            </ForgotPasswordLink>
            <div className="form-check">
              <Input 
                id="checkLogged"
                type="checkbox" 
                className="form-check-input" onClick={handleLogged} checked={keep}/>
              <LabelCheckBox className="form-check-label" htmlFor="checkLogged">Manter-se conectado</LabelCheckBox>
            </div>
          <ButtonGetIn disabled={loading} loading={loading} onClick={handleSubmit} type="submit">
            ENTRAR
          </ButtonGetIn>
     </Form>
     <Footer>
      Copyright &copy; 2021 GoodCasting - Todos os direitos reservados
     </Footer>
     </FirstColumn>
     <SecondColumn>
       <Imagembackground src={Images.bg_login.default} alt="bglogin">
        </Imagembackground> 
       </SecondColumn>
    </LoginStyled> 
    : <div>

    </div> 
  )
}

export default Login; 