import styled from 'styled-components';

export const Container = styled.div`
    padding: 1em 3em 3em 0px;

    & > h2 {
    margin-bottom: 56px;
    }
`;

export const Content = styled.div`
  background-color: #FFF;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 1em;
`;

export const Table = styled.table`
    width: 100%;
    font-size: 14px;
    text-align: center;

    & > thead {
        background-color: #F5F6FA;
        height: 48px;
        color: #828282;
        font-weight: bold;

        & > tr > td:last-child{
            width: 430px;
        }
    }

    & > tbody {
        font-weight: 300;

        & > tr {
            height: 50px;

            & > td:last-child{
                max-width: 430px;
                height: 50px;
                display: flex;  
                align-items: center;
                justify-content: space-around;

                & > button {
                    width: 40%;
                    min-height: 28px;
                    font-size: 12px;
                    border-radius: 5px;
                    padding: 0;
                }

                & > button:nth-child(2){
                    background-color: #DC1076;
                }
            }
        }
    }
`;