import React, { useState } from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { Handle, Track, TooltipRail } from "./components"; // example render components - source below

const sliderStyle = {
  position: "relative",
  width: "92%",
  marginTop: '10px',
  marginLeft: '15px'
};

const SliderComponent = ({domain, values,  selected, onChangeSelected, mode=2}) => {

  const onChange = (values) => {
    onChangeSelected(values);
    // const value = [range[0], values[1]]
    // setValues(value);
    // onChangeSelected(values[1])
    // console.log(value);
  };

  return (
    <div>
      <Slider
      mode={mode}
      step={1}
      domain={domain}
      rootStyle={sliderStyle}
      onUpdate={onChange}
      values={values} >
        <Rail>{railProps => <TooltipRail {...railProps} />}</Rail>
        <Handles>
          {({ handles, activeHandleID, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle, index) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  disabled={index === 0}
                  domain={domain}
                  isActive={handle.id === activeHandleID}
                  getHandleProps={index === 0 ? () => {} : getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  );
}

export default SliderComponent;