import React, {useState, useEffect} from "react";

import { CreateRecurring, GetRecurring, UpdateRecurring } from "../../services/recurringType";
import { Container, Row, Button, SelectType, Table } from "./style";
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import Input from "../../components/Input";
import { useAuth } from "../../AppContext/Context";
import { addToast, ERROR, SUCCESS } from "../../components/Notifications";

const RecurringPayment = () => {
  const {
    user: { is_type },
  } = useAuth();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [payload, setPayload] = useState({
    name: '',
    quantity: 0,
    type: ''
  });

  useEffect(() => {
    loadRecurringType();
  }, [])

  const loadRecurringType = async () => {
    try {
      setLoading(true);
      const response = await GetRecurring();
      setList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const resetPayload = () => {
    setPayload({
      name: '',
      quantity: 0,
      type: ''
    });
  }

  const handleChange = (field, value) => {
    setPayload({
      ...payload,
      [field]: value
    });
  }

  const handleEdit = (item) => {
    setPayload(item)
  }

  const checkDisableButton = () => {
    const {name, quantity, type} = payload;
    return !name || quantity < 1 || !type;
  }

  const updateList = (newItem, update=false) => {
    if(update) {
      const newList = [...list];
      const indexOf = list.findIndex(item => item.id === newItem.id);
      if(indexOf >= 0) {
        newList[indexOf] = newItem;
        setList(newList);
      }
    } else {
      setList(oldValue => [...oldValue, newItem])
    }
  }

  const handleSubmit = async () => {
    try {
      if(!payload.id){
        const response = await CreateRecurring(payload);
        resetPayload();
        updateList(response);
        addToast(SUCCESS, 'Criado com Sucesso');
      } else {
        const response = await UpdateRecurring(payload, payload.id);
        resetPayload();
        updateList(response, true);
        addToast(SUCCESS, 'Atualizado com Sucesso');
      }
    } catch (error) {
      console.log(error);
      addToast(ERROR, 'Ocorreu um problema com essa operação');
    }
  }

  return (
    <>
      <h2>Tipos de Pagamento Recorrente</h2>
      <Container>
        <Row>
          <Input label="Nome" type="text" value={payload.name} required onChange={({target}) => handleChange('name', target.value)}/>
          <Input label="Quantidade" type="number" value={payload.quantity} required onChange={({target}) => handleChange('quantity', Number(target.value))}/>

          <div>
            <label>Tipo</label>
            <SelectType
            value={payload.type}
            required
            onChange={({target}) => handleChange('type', target.value)}>
              <option hidden>Escolha</option>
              <option value="DIAS">Dias</option>
              <option value="MESES">Meses</option>
              <option value="ANOS">Anos</option>
            </SelectType>
          </div>

          <Button 
          green={!checkDisableButton()} 
          disabled={checkDisableButton()}
          onClick={handleSubmit}>
            Confirmar
          </Button>
        </Row>

        <Table large>
        <thead>
          <th>Nome</th>
          <th>Quantidade</th>
          <th>Tipo</th>
          <th></th>
        </thead>
          <tbody>
            {loading === true ? (
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            ) :
            (list.map((recurring, index) => (
              <tr key={recurring.id}>
                <td>{recurring.name}</td>
                <td>{recurring.quantity}</td>
                <td>{recurring.type}</td>
                <td>
                  {checkPermission(
                    is_type,
                    MODELS.Pagamento,
                    FUNCTIONS.Atualizar
                  ) ? (
                    <Button
                    green={true}
                    onClick={() => handleEdit(recurring)}>
                      EDITAR
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))
            )
            }
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default RecurringPayment;
