import React, { useState } from 'react';

import Input from '../../../components/Input';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, SUCCESS, ERROR } from '../../../components/Notifications';
import { CreateType } from '../../../services/permission';
import {
  ModalContainer, ModalBody, ModalButton
} from './styles';

const TypeModal = ({ toggleModal }) => {

  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({ name: '' })

  async function handleSubmit() {
    if (payload.name) {
      setLoading(true);
      const response = await CreateType(payload);
      setLoading(false);
      if(response) {
        addToast(SUCCESS, 'Tipo de usuário criado com sucesso');
        toggleModal(true);
      } else {
        addToast(ERROR, 'Não foi possível criar um tipo novo');
      }
    }
  }

  return (
    <ModalContainer>

      <ModalBody>
        <div className='text-center'>
          <h2>Criar Novo Tipo de Usuário</h2>
        </div>
        <Input label="Nome" onChange={({ target }) => setPayload({ ...payload, name: target.value })} />
        {loading ? <LoadingSpinner noMargin={true} /> : <div className="d-flex justify-content-end">
          <ModalButton blue onClick={handleSubmit}>CRIAR</ModalButton>
          <ModalButton onClick={toggleModal}>CANCELAR</ModalButton>
        </div>}
      </ModalBody>
    </ModalContainer>
  )
}

export default TypeModal;