import styled from 'styled-components'

export const AllChartSpace = styled.div`
  display: flex;
`

export const ChartContainer = styled.div`
  height: 150px;
  width: 310px;
  display: flex-box;
  background-color: #FFF;
  margin-right: 1rem;
  border-radius: 10px;
  span {
    font-size: 13.5px;
    height: 30px;
    width: 160px;
    margin: auto 0 0 7px;
    color: #828282;
  }
`
 export const CharLayout = styled.div` 
    margin: -70px 15px 0 auto;
  `

export const DataBox = styled.div`
  margin: 55px 0 0 6px;
  height: 60px;
  width: 150px;
  color: #828282;

  h4 {
    font-weight: bold;
  }

  p{
    font-size: 12px;
  }

  img {
    margin-left:0;
    margin-bottom: 2px;
  }
  span {
    color: #24C855;
    margin-left: 3px;
  }
` 