import axios from "axios"; 
import { AUTH_URL, SESSION_URL } from "./config";

export const authentication = async (payload) => {
  try {
    const response = await axios.post(AUTH_URL, payload);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const retrive = async (localUser) => {

  try {
    const response = await axios.get(SESSION_URL, {
      headers: {
        Authorization: `Token ${localUser.token}`,
      }
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}