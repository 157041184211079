import styled from "styled-components";

export const Container = styled.div`
  width: 96%;
  height: 74vh;
  background-color: #FFF;
  margin: 50px 0 50px 0;
`

export const ContainerClass = styled.div`
  width: 96%;
  height: 66vh;
  background-color: #FFF;
  margin: 50px 0 50px 0;
`
export const ContainerNotificacao = styled.div`
  width: 96%;
  height: 66vh;
  background-color: #FFF;
  margin: 50px 0 50px 0;
`
export const ContainerAvaliacao = styled.div`
  width: 96%;
  height: 66vh;
  background-color: #FFF;
  margin: 50px 0 50px 0;
`

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const AvaliacaoSpace = styled.div`
  margin: 0 20px 0 20px;
  height: 260px;
  width: 340px;
`

export const InputsArea = styled.div`
  margin: 0 20px 0 20px;
  width: 450px;
`


export const Function = styled.div`
  height: 260px;
  width: 170px;
`
export const FunctionEdit = styled.div`
  height: 260px;
  width: 170px;
`

export const SelectFunction = styled.select`
  // width: 180px; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`
export const Label = styled.label`
`

export const LabelAvaliacao = styled.label`
  border: 1px solid grey;
  width: 320px;
  height: 44px;
  margin: 8px 0 0 4px;
  padding: 8px 0 0 10px;
`

export const LabelStar = styled.label`
  .star{
    cursor: pointer; 
    transition: color 200ms;
  }
`
export const InputStar = styled.input`
  display: none;
`

export const InputText = styled.input`
  width: 320px;
  height: 44px;
  margin: 8px 0 0 4px;
  padding: 0 0 0 10px;
`

export const InputTextClass = styled.input`
width: 100%;
height: 44px;
margin: 8px 0 0 4px;
padding: 0 0 0 10px;
border-radius: 8px;
`

export const Button = styled.button`
  background-color: ${(props) => props.loading ? "#707070" : "#86fefa"};
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 22px 34px 0 auto;
  `

  export const ButtonArea = styled.div`
    display: flex;
    margin-left: auto;
  `

export const DescricaoArea = styled.div`
  width: 100%;
  height: 250px;  
  margin: 0 20px 0 20px;
`
export const DescricaoAreaClass = styled.div`
  width: 98%;
  height: 36vh;  
  margin: 0 2% 0 2%;
`

export const UpDivs = styled.div`
  display: flex;
  justify-content: space-between;
  // height: 44vh;
`
export const UpDivsClass = styled.div`
  display: flex;
  height: 30vh;
`
export const UpDivsAvaliacao = styled.div`
  display: flex;
  height: 36vh;
`

export const InputDescricao = styled.textarea`
  margin-top: 2%;
  width: 96%;
  height: 50%;
  padding: 4px 0 0 6px;
`

export const FunctionNotify = styled.div`
  height: 120px;
  width: 200px;
  margin: -5% 0 0 2%;
`;
