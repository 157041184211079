import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import LoadingSpinner from "../LoadingSpinner/index.js";
import {
  TableCustom, ButtonEdit, ModalContainer, ModalButton, ModalBody
} from './styles.js'
import { GetListaArtista, RejectArtist } from '../../services/artist'
import { getDate } from '../../Helpers/index'
import { addToast, SUCCESS, ERROR } from "../Notifications";


function TabelaAprovacaoArtista() {
  let history = useHistory();

  const [modal, setModal] = useState(false);
  const [dataArtist, setDataArtist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    reason: '',
    description: ''
  });
  const [load, setLoad] = useState(false);

  useEffect(() => {
    listaArtista()
  }, [])

  function handleTriggerModal(customer) {
    setModal(customer);
    setPayload({ reason: '', description: '' });
  }

  function handleClick(profile) {
    history.push({
      pathname: "/artista/visualizar-perfil", state: {
        profile
      }
    })
  }

  function handleChangeReject(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function listaArtista() {
    setLoad(true)
    const resp = await GetListaArtista();
    if (resp) {
      setDataArtist(resp.data)
      setLoad(false)
    } else {
      setLoad(false)
      addToast(ERROR, "Nenhum dado retornado");
    }
  }

  async function rejectModal() {
    setLoading(true);
    const resp = await RejectArtist({ ...payload, artist: modal.id })
    setLoading(false);
    if (resp && resp.status === 200) {
      addToast(SUCCESS, "Artista cancelado com sucesso!");
      handleTriggerModal(false);
      listaArtista();
    } else {
      addToast(ERROR, "Não foi possível realizar essa operação");
    }
  }

  function validForm() {
    if (!payload.reason || !payload.description) {
      addToast(ERROR, 'Informe o Motivo da rejeição');
      return false;
    }
    return true;
  }

  return (
    <div>
      <h2>Aprovação Artistas</h2>

      <TableCustom large>
        <thead>
          <th>Nome</th>
          <th>Início</th>
          <th>Status</th>
          <th>Email</th>
          <th>Telefone</th>
          <th>Funções</th>
        </thead>
        <tbody>
          {load === true ?
            <div className="text-center m-5">
              <div className="spinner-border text-info" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div> :
            dataArtist.map((profile, index) => (
              <tr key={index}>
                <td>{profile.name}</td>
                <td>{getDate(profile.created_at)}</td>
                <td>{profile.status}</td>
                <td>{profile.email}</td>
                <td>{profile.phone}</td>
                <td>
                  <ButtonEdit azul large font12 onClick={() => handleClick(profile)}>VISUALIZAR PERFIL</ButtonEdit>
                  <ButtonEdit large font12 onClick={() => setModal(profile)}>
                    REJEITAR PERFIL
                  </ButtonEdit>
                </td>
              </tr>
            ))}

        </tbody>

      </TableCustom>
      {modal &&
        <ModalContainer>
          <ModalBody>
            <div>
              <h2>Tem certeza que deseja excluir o Perfil?</h2>
            </div>
            {loading ? <LoadingSpinner noMargin={true} /> : <>
              <ModalButton blue onClick={rejectModal}>EXCLUIR</ModalButton>
              <ModalButton onClick={() => handleTriggerModal(false)}>CANCELAR</ModalButton>
            </>}
          </ModalBody>
        </ModalContainer>
      }
    </div>
  )
}

export default TabelaAprovacaoArtista;
{/* <Modal 
loading={loading} handleChangeReject={handleChangeReject}
closeModal={handleTriggerModal} rejectModal={rejectModal}/> */}