import React, { useState, useRef, useEffect } from 'react';
import MaskInput from 'react-maskinput';
import { useHistory } from 'react-router';

import {
  FirstDiv,
  SelectGender,
  FormCustom,
  ElemtDiv,
  InputSlide,
  SelectRecurrency
} from './style';
import {
  DivCustom,
  DivLeft,
  DivCentral,
  DivRight,
  ElemtDivCabelo,
  ElemtDivOlhos,
  ElementDivAltura,
  ElementDivCintura,
  ElementDivQuadril,
  ElementDivBusto,
  ButtonCabelo,
  Hr,
  DivFotos,
  ButtonAddFoto,
  ButtonSalvar,
  CustomCurrencyInput
} from '../../components/EditPerfil/style';

import { FormHalf } from '../../pages/Customer/Edit/styles';
import Slider from "../../components/Slider";

import Input from '../../components/Input';

import Adicao from '../../assets/adicao.png';
import { PostArtista } from '../../services/artist';
import { uploadArtistPhoto } from '../../services/upload';
import { addToast, ERROR, SUCCESS } from '../../components/Notifications';
import { codigoPaises } from '../../Helpers/index'
import { GetRecurring } from '../../services/recurringType';

function CadastroArtista() {
  const history = useHistory();
  const inputRef = useRef();

  const [height, setHeight] = useState(170);
  const [waist, setWaist] = useState(70);
  const [hip, setHip] = useState(95);
  const [bust, setBust] = useState(28);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [preview, setPreview] = useState([]);

  const [phoneMask, setPhoneMask] = useState('(000) 00000-0000');
  const [recurringTypeList, setRecurringTypeList] = useState([]);
  const [payload, setPayload] = useState({
    name: '',
    birth_date: '',
    address_city: '',
    email: '',
    phone_prefix: '+1',
    phone: '',
    instagram: '',
    hair: '',
    gender: '',
    eye: '',
    skin: '',
    stature: 160,
    waist: 80,
    hip: 90,
    bust: 85,
    monthly_fee: 0,
    expiration_date: '',
    recurring_payment_type: null
  });

  useEffect(() => {
    loadRecurringType();
  }, [])

  async function loadRecurringType () {
    try {
      const response = await GetRecurring();
      setRecurringTypeList(response);
    } catch (error) {
      console.log(error)
    }
  }

  function handleChange(name, value) {
    if (name === 'phone_prefix') {
      setPayload({ ...payload, [name]: value });
      if (value === '+1') {
        setPhoneMask('(000) 00000-0000');
      } else if (value === '+55') {
        setPhoneMask('(00) 00000-0000');
      } else if (value === '+351') {
        setPhoneMask('(000) 00000-0000');
      }
    } else if (name === 'birth_date' || name === 'expiration_date') {
      setPayload({
        ...payload, [name]: `${value}T00:00:00`
      })
    } else if (name === 'stature' || name === 'waist' || name === 'hip' || name === 'bust') {
      if(value >= 1 && value <= 400) {
        setPayload({ ...payload, [name]: value });
      } else {
        return ;
      }
    } else {
      setPayload({ ...payload, [name]: value });
    }
  }

  function handleFileUpload(event) {
    const _imgs = [...event.target.files];
    Promise.all(
      _imgs.map(img => {
        return (
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener('error', reject);
            reader.readAsDataURL(img);
          })
        );
      })
    )
      .then(_images => {
        /* Once all promises are resolved, update state with image URI array */
        setPreview([...preview, ..._images]);
        setImages([...images, ..._imgs]);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function handleRemoveImage(imgIndex) {

    let _newPreview = [...preview];
    let _newImages = [...images];

    _newPreview.splice(imgIndex, 1);
    _newImages.splice(imgIndex, 1);

    setPreview(_newPreview);
    setImages(_newImages);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (validForm()) {
      setLoading(true);
      if (images.length > 0) {
        try {
          const imgsToUpload = new FormData();
          for (const _image of images) {
            imgsToUpload.append("file", _image);
          }

          let imagesToSend = [];

          try {
            const imageUpload = await uploadArtistPhoto(imgsToUpload);
            imagesToSend = imageUpload.result.map(imgToSend => imgToSend.Location);
          } catch (err) {
            setLoading(false);
            if(err.response.data.message === "Maximum size of file reached") {
              return addToast(ERROR, "Foto ultrapassa limite de 6 MB");
            }
            return addToast(ERROR, 'Houve um problema ao enviar essa Foto');
          }
          
          const toSend = {
            ...payload,
            images: imagesToSend,
          };

          const resp = await PostArtista(toSend);
          setLoading(false);
          if (resp) {
            addToast(SUCCESS, "Dados salvos com sucesso!");
            history.push('/artista/aprovacao');
          }
        } catch (err) {
          setLoading(false);
          addToast(ERROR, "Ocorreu um erro ao cadastrar artista");
        }
      } else {
        const resp = await PostArtista(payload);
        setLoading(false);
        if (resp) {
          addToast(SUCCESS, "Dados salvos com sucesso!");
          history.push('/artista/aprovacao');
        }
      }
    }
  }

  function validForm() {
    const checkNumber = /\d+/g;
    const checkString = /^\d+$/;
    
    if (!payload.hair || !payload.skin || !payload.eye) {
      addToast(ERROR, 'Informe todos os dados');
      return false;
    }
    if (!payload.gender) {
      addToast(ERROR, 'Informe o gênero');
      return false;
    }
    if (payload.name.match(checkNumber) > 0) {
      addToast(ERROR, 'Ops!', 'Não é permitido números no campo nome.');
      return false;
    }
    return true;
  }

  function checkFileSize(event) {
    const _imgs = [...event.target.files];
    return _imgs.find(file => file.size >= 6291456);
  }

  return (
    <>
      <h2>Cadastro Artista</h2>
      <FormCustom onSubmit={handleSubmit}>
        <ElemtDiv>
          <FirstDiv>
            <FormHalf row>
              <Input
                label="Nome Completo (somente letras)" title="Somente letras nesse campo"
                type="text" placeholder="Ex. Danilo Alvarenga"
                onChange={({ target }) => handleChange('name', target.value)} required />
            </FormHalf>
            <FormHalf row>
              <div style={{ marginRight: 10 }}>
                <Input
                  label="Data Nascimento" title="Somente números nesse campo"
                  type="date"
                  onChange={({ target }) => handleChange('birth_date', target.value)} required />
              </div>

              <div style={{ marginRight: 10 }}>
                <Input
                  label="Data Expiração" title="Somente números nesse campo"
                  type="date"
                  onChange={({ target }) => handleChange('expiration_date', target.value)} required />
              </div>

              <Input
                label="Cidade de Atuação" type="text" placeholder="Ex. Lisboa"
                onChange={({ target }) => handleChange('address_city', target.value)} required />
            </FormHalf>

            <FormHalf row>
              <div style={divColumn}>
                <label>Telefone</label>
                <div style={divCustom}>
                  <select
                    value={payload.phone_prefix}
                    onChange={({ target }) => handleChange('phone_prefix', target.value)}>
                    {codigoPaises.map((item) => (
                      <option>{item}</option>
                    ))}
                  </select>
                  <MaskInput
                    required
                    mask={phoneMask}
                    placeholder={phoneMask}
                    onChange={({ target }) => handleChange('phone', target.value)}
                    style={style} />
                </div>
              </div>
            </FormHalf>

            <FormHalf>
              <Input label="Email" type="email" placeholder="seunome@email.com"
                onChange={({ target }) => handleChange('email', target.value)} required />
            </FormHalf>

            <FormHalf>
              <Input
                label="Instagram" type="text" placeholder="@seuinstagram" required
                onChange={({ target }) => handleChange('instagram', target.value)} />
            </FormHalf>

            <FormHalf row>
              <div style={{ marginTop: 20 }}>
                Gênero <br />
                <SelectGender
                  required
                  onChange={({ target }) => handleChange('gender', target.value)}>
                  <option hidden>Escolha um Gênero</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Trans Masculino">Trans Masculino</option>
                  <option value="Trans Feminino">Trans Feminino</option>
                  <option value="Outros">Outros</option>
                  <option value="Todos">Todos</option>
                </SelectGender>
              </div>

              <div style={{ marginTop: 22 }}>
                Valor Pagamento <br />
                <CustomCurrencyInput 
                prefix="€ "
                value={payload.monthly_fee}
                onChange={(masked, raw) => {
                  handleChange("monthly_fee", raw);
                }} />
              </div>

              <div style={{ marginTop: 22 }}>
                Tipo Recorrência <br />
                <SelectRecurrency
                  required
                  onChange={({ target }) => handleChange('recurring_payment_type', target.value)}>
                  <option hidden>Seleciona</option>
                  {recurringTypeList.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>  
                  ))}
                </SelectRecurrency>
              </div>
            </FormHalf>
          </FirstDiv>
        </ElemtDiv>

        <DivCustom>
          <DivLeft>
            <ElemtDivCabelo>
              <label>Cabelo</label><Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={payload.hair === "Castanho Escuro" ? true : false}
                  onClick={() => handleChange('hair', "Castanho Escuro")}>
                  Castanho Escuro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.hair === "Loiro" ? true : false}
                  onClick={() => handleChange('hair', "Loiro")}>
                  Loiro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.hair === "Ruivo" ? true : false}
                  onClick={() => handleChange('hair', "Ruivo")}>
                  Ruivo
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.hair === "Grisalho" ? true : false}
                  onClick={() => handleChange('hair', "Grisalho")}>
                  Grisalho
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.hair === "Castanho Claro" ? true : false}
                  onClick={() => handleChange('hair', "Castanho Claro")}>
                  Castanho Claro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.hair === "Colorido" ? true : false}
                  onClick={() => handleChange('hair', "Colorido")}>
                  Colorido
                </ButtonCabelo>
              </div>
            </ElemtDivCabelo>

            <ElemtDivOlhos>
              <label>Olhos</label><Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={payload.eye === "Castanho Escuro" ? true : false}
                  onClick={() => handleChange("eye", "Castanho Escuro")}>
                  Castanho Escuro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.eye === "Verde" ? true : false}
                  onClick={() => handleChange("eye", "Verde")}>
                  Verde
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.eye === "Azul" ? true : false}
                  onClick={() => handleChange("eye", "Azul")}>
                  Azul
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.eye === "Castanho Claro" ? true : false}
                  onClick={() => handleChange("eye", "Castanho Claro")}>
                  Castanho Claro
                </ButtonCabelo>
              </div>
            </ElemtDivOlhos>
            <ElemtDivOlhos>
              <label>Pele</label><Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={payload.skin === "Branca" ? true : false}
                  onClick={() => handleChange("skin", "Branca")}>
                  Branca
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.skin === "Negra" ? true : false}
                  onClick={() => handleChange("skin", "Negra")}>
                  Negra
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.skin === "Morena" ? true : false}
                  onClick={() => handleChange("skin", "Morena")}>
                  Morena
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.skin === "Indígena" ? true : false}
                  onClick={() => handleChange("skin", "Indígena")}>
                  Indígena
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.skin === "Asiática" ? true : false}
                  onClick={() => handleChange("skin", "Asiática")}>
                  Asiática
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={payload.skin === "Sul Asiática - Índia, Paquistão, etc." ? true : false}
                  onClick={() => handleChange("skin", "Sul Asiática - Índia, Paquistão, etc.")}>
                  Sul Asiática - Índia, Paquistão, etc.
                </ButtonCabelo>
              </div>
            </ElemtDivOlhos>
          </DivLeft>

          <DivCentral>
            <ElementDivAltura>
              <label>Altura: <InputSlide value={payload.stature} type="number" onChange={({target}) => handleChange('stature', Number(target.value))} /> cm</label><Hr></Hr>
              <p>0 - 400</p>
              <Slider domain={[0, 400]} values={[payload.stature]} selected={height} onChangeSelected={(value) => handleChange('stature', value[0])} />
            </ElementDivAltura>
            <ElementDivCintura>
              <label>Cintura: <InputSlide value={payload.waist} type="number" onChange={({target}) => handleChange('waist', Number(target.value))} /> cm</label><Hr></Hr>
              <p>0 - 400</p>
              <Slider domain={[0, 400]} values={[payload.waist]} selected={waist} onChangeSelected={(value) => handleChange('waist', value[0])} />
            </ElementDivCintura>
          </DivCentral>

          <DivRight>
            <ElementDivQuadril>
              <label>Anca: <InputSlide value={payload.hip} type="number" onChange={({target}) => handleChange('hip', Number(target.value))} /> cm</label><Hr></Hr>
              <p>0 - 400</p>
              <Slider domain={[0, 400]} values={[payload.hip]} selected={hip} onChangeSelected={(value) => handleChange('hip', value[0])} />
            </ElementDivQuadril>
            <ElementDivBusto>
              <label>Peito: <InputSlide value={payload.bust} type="number" onChange={({target}) => handleChange('bust', Number(target.value))} /> cm</label><Hr></Hr>
              <p>0 - 400</p>
              <Slider domain={[0, 400]} values={[payload.bust]} selected={bust} onChangeSelected={(value) => handleChange('bust', value[0])} />
            </ElementDivBusto>
          </DivRight>
        </DivCustom>

        <DivFotos>
          {preview.map((item, index) => (
            <div key={index}>
              <img src={item} alt="Modelo" />
              <span onClick={() => handleRemoveImage(index)}>Remover</span>
            </div>
          ))}
          <ButtonAddFoto type="button" onClick={() => inputRef.current.click()}>
            <img src={Adicao} /><br /> FOTO
          </ButtonAddFoto>
          <input
            ref={inputRef} accept="image/png, image/jpeg" type="file"
            multiple hidden onInput={(event) => {
              if(!checkFileSize(event)) {
                handleFileUpload(event);
              } else { 
                addToast(ERROR, "Foto ultrapassa limite de 6 MB");
              }
            }} 
            onClick={(event) => event.target.value = null}/>
        </DivFotos>
        {loading ?
          <div style={{ textAlign: 'right' }}>
            <div className="spinner-border text-info" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
          : <ButtonSalvar>SALVAR ALTERAÇÕES</ButtonSalvar>}

      </FormCustom>

    </>
  )
}

export default CadastroArtista;

const style = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid #828282',
  borderRadius: '10px',
  padding: '0px 16px 0px 16px',
  height: '40px',
  marginRight: '4px'
}

const divCustom = {
  display: 'flex',
}

const divColumn = {
  display: 'flex',
  flexDirection: 'column'
}