import axios from "axios";
import {ERROR,  addToast } from "../components/Notifications";

import { 
  APPROVED_ARTIST_URL, ARTIST_REJECT, GET_URL_ARTISTA,
  ARTIST_PHOTO
} from "./config";

export const GetAllArtist = async () => {
  try {
    const resp = await axios.get(GET_URL_ARTISTA);
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

export const GetListaArtista = async(currentPage) => {
  try{
    const resp = await axios.get(`${GET_URL_ARTISTA}`,{
      params: {
        page:currentPage,
        status:"EM ANALISE"
      }
    })
    return resp;
  } catch(error){
    console.log("Erro: ", error);
  }
}

export const GetArtistPhotos = async (artistId) => {
  try {
    const resp = await axios.get(`${ARTIST_PHOTO}`, {
      params: {
        artist: artistId
      }
    });
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

export const GetPaginatedArtists = async(currentPage) =>{
  try{
    const resp = await axios.get(`${GET_URL_ARTISTA}`,{
      params: {
        page:currentPage,
      }
    })
    return resp;
  } catch(error){
    console.log("Erro: ", error);
  }
}

export const GetSearchArtists = async(params) =>{
  try{
    const resp = await axios.get(`${GET_URL_ARTISTA}`,{
      params: {...params}
    })
    return resp.data;
  } catch(error){
    console.log("Erro: ", error);
  }
}

export const GetPerfilArtista = async(id) =>{
  try {
    const resp = await axios.get(`${GET_URL_ARTISTA}/${id}`)
    console.log(resp.data);
  } catch (error) {
    console.log("Erro: ", error);
  }
}

export const PostArtista = async(payload) =>{
  try {
    const resp = await axios.post(`${GET_URL_ARTISTA}`, payload)
    return resp;
  } catch (error) {
    if(error.response.status === 400){
      if(error.response.data[0] === "Usuário já existe"){
        addToast(ERROR, "Usuário já existe", "Já existe um usuário cadastrado com esse Email");
      }
      console.log("Erro: ", error);
    } 
  }
  return ;
}

export const SendArtistPhoto = async (photo, artistId) => {
  const formData = new FormData();
  formData.append('image', photo);
  formData.append('artist', artistId);

  try {
    const resp = await axios.post(ARTIST_PHOTO, formData);
    return resp.data; 
  } catch (error) {
    console.log(error);
  }
}

export const PatchArtista = async(data) =>{
  try {
    const resp = await axios.patch(`${GET_URL_ARTISTA}${data.id}/`, data)
    return resp;
  } catch (error) {
    console.log("Error: ", error);
  }
}

export const ApprovedArtista = async (id) => {
  try {
    const resp = await axios.post(`${APPROVED_ARTIST_URL}`, {
      artist: id
    });
    return resp;
  } catch (error) {
    throw error;
  }
}

export const RejectArtist = async (payload) => {
  try {
    const resp = await axios.post(`${ARTIST_REJECT}`, payload);
    return resp;
  } catch (error) {
    console.log(error);
  }
}

export const DeleteArtist = async (artistId) => {
  try {
    const resp = await axios.delete(`${GET_URL_ARTISTA}${artistId}`);
    return resp;
  } catch (error) {
    console.log(error);
  }
}

export const SelectArtistPhoto = async (photo) => {
  try {
    const resp = await axios.patch(`${ARTIST_PHOTO}${photo.id}/`, {
      is_active: !photo.is_active
    });
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

export const DeleteArtistPhoto = async (photoId) => {
  try {
    const resp = await axios.patch(`${ARTIST_PHOTO}${photoId}/`, {
      disabled: true
    });
    return resp.data; 
  } catch (error) {
    console.log(error);
  }
}