const Images = { 
  icon_calendar: require('./HomeDashboard/ic_calendar.svg'),
  icon_dashboard: require('./HomeDashboard/ic_dashboard.svg'),
  icon_bell: require('./HomeDashboard/ic-bell.svg'),
  icon_help: require('./HomeDashboard/ic_help.svg'),
  icon_home: require('./HomeDashboard/ic_home.svg'),
  icon_inbox: require('./HomeDashboard/ic_inbox.svg'),
  icon_message: require('./HomeDashboard/ic_message.svg'),
  icon_setting: require('./HomeDashboard/ic_setting.svg'),
  icon_users: require('./HomeDashboard/ic_users.svg'),
  icon_arrow_narrow_up1: require('./HomeDashboard/ic-arrow-narrow-up-1.svg'),
  icon_arrow_narrow_up2: require('./HomeDashboard/ic-arrow-narrow-up-2.svg'),
  icon_arrow_narrow_up3: require('./HomeDashboard/ic-arrow-narrow-up-3.svg'),
  icon_arrow_narrow_up4: require('./HomeDashboard/ic-arrow-narrow-up-4.svg'),
  icon_arrow_narrow_up: require('./HomeDashboard/ic-arrow-narrow-up.svg'),
  logo: require('./HomeDashboard/logo.png'),
  bg_login: require('./HomeDashboard/backgroundlogin.jpg'),
  ic_class: require('./HomeDashboard/ic_class.png') 
}

export default Images; 