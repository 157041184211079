import React, { useEffect, useState } from 'react'
import { ButtonEdit } from '../TabelaAprovacaoArtista/styles'
import { CardCustom, FormCustom, SectionCustom } from './style'
import { useLocation, useHistory } from 'react-router';
import { ApprovedArtista, GetArtistPhotos, PatchArtista, RejectArtist, SelectArtistPhoto } from '../../services/artist'
import Modal from '../Modal/Modal';
import { addToast, SUCCESS, ERROR } from '../Notifications'
import LoadingSpinner from '../LoadingSpinner';

function AprovacaoArtista() {
  const history = useHistory();
  const location = useLocation();

  const [profileArtist, setProfileArtist] = useState(null)
  const [photos, setPhotos] = useState([]);
  const [loadPhoto, setLoadPhoto] = useState(false);
  const [selectLoad, setSelectLoad] = useState(false);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    reason: '',
    description: ''
  });

  useEffect(() => {
    if (!location.state) {
      return history.push("/artista/aprovacao")
    }
    setProfileArtist(location.state.profile)
    loadPhotos(location.state.profile.id);
  }, [])

  async function loadPhotos(artistId) {
    setLoadPhoto(true);
    const resp = await GetArtistPhotos(artistId);
    setLoadPhoto(false);
    if (resp) {
      setPhotos(resp);
    }
  }

  async function submitApproved() {
    if (checkPhotosCount() > 3) {
      const photo_profile = photos.find(img => img.is_active);
      try {
        setLoading(true);
        const resp = await ApprovedArtista(profileArtist.id);
        await PatchArtista({
          id: profileArtist.id,
          image: photo_profile.image
        });
        setLoading(false);
        if (resp && resp.status === 200) {
          addToast(SUCCESS, "Artista aprovado com sucesso!");
          history.push("/artista/aprovacao")
        }
      } catch (error) {
        setLoading(false);
        const {response} = error;
        if(response && response.data.error === "error-send-email") {
          addToast(ERROR, 'Houve um problema ao aprovar o artista', 'O Email do artista está errado ou não existe');
          return ;
        }
        if(response.data.error === "error-required-fields") {
          const {message} = response.data.response;
          addToast(ERROR, message);
          return ;
        }
      }
    } else {
      addToast(ERROR, 'Selecione ao menos 4 Fotos');
    }
  }

  function checkPhotosCount() {
    const sum = photos.reduce((count, item) => {
      if (item.is_active) {
        return count + 1;
      }
      return count;
    }, 0);
    return sum;
  }

  function handleTriggerModal(customer) {
    setModal(customer);
    setPayload({ reason: '', description: '' });
  }

  function handleChangeReject(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function rejectModal() {
    if (validForm()) {
      setLoading(true);
      const resp = await RejectArtist({ ...payload, artist: modal.id })
      setLoading(false);
      if (resp && resp.status === 200) {
        addToast(SUCCESS, "Artista cancelado com sucesso!");
        handleTriggerModal(false);
        history.push("/artista/aprovacao")
      } else {
        addToast(ERROR, "Não foi possível realizar essa operação");
      }
    }
  }

  function validForm() {
    if (!payload.reason || !payload.description) {
      addToast(ERROR, 'Informe o Motivo da rejeição');
      return false;
    }
    return true;
  }

  async function handleSelectPhoto(photo) {
    if(!selectLoad) {
      if(checkPhotosCount() < 4 || photo.is_active) {
        setSelectLoad(photo.id);
        const resp = await SelectArtistPhoto(photo);
        setSelectLoad(false);
        if (resp) {
          const _new = [...photos].map(item => {
            if (item.id === photo.id) {
              item.is_active = !item.is_active;
            }
            return item;
          });
          setPhotos(_new);
          addToast(SUCCESS, 'Foto selecionada');
        } else {
          addToast(ERROR, 'Não foi possível realizar essa operação');
        }
      } else {
        addToast(ERROR, 'Selecione apenas 4 fotos');
      }
    }
  }

  const formatDate = (time, onlyDate) => {
    const _date = new Date(time);
    if (onlyDate) {
      return `${_date.toLocaleDateString('pt-pt', { timeZone: 'UTC' })}`;
    } else {
      return `${_date.toLocaleDateString('pt-pt').split('/').reverse().join('/')}`;
    }

  }

  return (
    <>
      <h2>Aprovação de Perfil</h2>
      <FormCustom large>
        <div className="justify-content-between">
          {profileArtist && (
            <h4>Artista - {profileArtist.nick_name}</h4>
          )}
          {loading ? <LoadingSpinner /> : <>
            <ButtonEdit type="button" azul font12 style={styleButtonAzul} onClick={submitApproved}>APROVAR CADASTRO</ButtonEdit>
            <ButtonEdit type="button" font12 onClick={() => setModal(profileArtist)}>REJEITAR CADASTRO</ButtonEdit>
          </>}
        </div>

        {profileArtist && (
          <div style={divCustom}>
            <h6>Data Nascimento: <span>{formatDate(profileArtist.birth_date)}</span></h6>
            <h6>Gênero: <span>{profileArtist.gender}</span></h6>
            <h6>Distrito: <span>{profileArtist.address_city}</span></h6>
            <h6>Altura: <span>{profileArtist.stature} cm</span></h6>
            <h6>Cintura: <span>{profileArtist.waist} cm</span></h6>
            <h6>Anca: <span>{profileArtist.hip} cm</span></h6>
            <h6>Peito: <span>{profileArtist.bust} cm</span></h6>
            <h6>Olhos: <span>{profileArtist.eye}</span></h6>
            <h6>Cabelo: <span>{profileArtist.hair}</span></h6>
            <h6>Cor de Pele/Etnia: <span>{profileArtist.skin}</span></h6>
            <h6>Email: <span>{profileArtist.email}</span></h6>
            <h6>Prefixo: <span>{profileArtist.phone_prefix}</span></h6>
            <h6>Telefone: <span>{profileArtist.phone}</span></h6>
            <h6>Instagram: <span>{profileArtist.instagram}</span></h6>
          </div>
        )}

        <SectionCustom>
          <h5 style={divH6}>Selecione as fotos adequadas para o perfil:</h5>
          {photos.map((photo) => (
            <CardCustom key={photo.id}>
              <img src={photo.image} alt="Modelo" />
              <div style={divCustom2}>
                {selectLoad === photo.id ? <LoadingSpinner noMargin={true} center={true} /> : <>
                  <input type="checkbox" id={`select${photo.id}`} value="Selecionar"
                    checked={photo.is_active} onClick={() => handleSelectPhoto(photo)}></input>
                  <label style={Label} for={`select${photo.id}`}>Selecionar</label>
                </>}
              </div>
            </CardCustom>
          ))}
        </SectionCustom>
      </FormCustom>
      {modal && <Modal
        handleChangeReject={handleChangeReject} closeModal={handleTriggerModal}
        rejectModal={rejectModal} />}
    </>
  )
}

export default AprovacaoArtista;

const styleButtonAzul = {
  marginLeft: "auto",
  marginRight: "10px"
}

const divCustom = {
  marginTop: "30px",
  alignItems: "baseline"
}

const divCustom2 = {
  display: "flex",
  alignItems: "flex-end",
  marginLeft: "10px",
  marginBottom: "5px"
}

const Label = {
  marginTop: "12px"
}

const divH6 = {
  fontSize: "14px",
  marginTop: "30px"
}