import styled from "styled-components";

export const ContainerNotificacao = styled.div`
  width: 96%;
  height: 66vh;
  background-color: #FFF;
  margin: 50px 0 50px 0;
`

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Label = styled.label`
  margin-top: 8px;
`

export const Button = styled.button`
  background-color: ${(props) => props.loading ? "#707070" : "#86fefa"};
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 22px 34px 0 auto;
`

export const DescricaoAreaClass = styled.div`
  width: 98%;
  height: 36vh;  
  margin: 0 2% 0 2%;
`

export const InputDescricao = styled.textarea`
  margin-top: 2%;
  width: 96%;
  height: 50%;
  padding: 4px 0 0 6px;
`

export const FunctionNotify = styled.div`
  height: 120px;
  width: 200px;
  margin: -5% 0 16px 2%;
`

export const SelectFunction = styled.select`
  
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`

export const InputTextClass = styled.input`
  width: 350px;
  height: 44px;
  margin: 8px 0 0 4px;
  padding: 0 0 0 10px;
  border-radius: 8px;
`