import axios from "axios";

import { CLASS_URL } from "./config";

export const getClassVideos = async (page) => {
  const params = { page };
  try {
    const response = await axios.get(CLASS_URL, { params });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const createClassVideo = async (payload) => {
  try {
    const response = await axios.post(CLASS_URL, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const updateClassVideo = async (id, payload) => {
  try {
    const response = await axios.patch(`${CLASS_URL}${id}/`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const deleteClass = async (id) => {
  try {
    const response = await axios.delete(`${CLASS_URL}${id}`);
    return true;
  } catch (error) {
    console.log(error);
  }
}