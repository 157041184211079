import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Content, Form, FormRow, FormHalf, Header } from './styles';
import { PatchClient } from '../../../services/client';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, SUCCESS, ERROR } from '../../../components/Notifications';
import { codigoPaises } from '../../../Helpers/index'

import MaskInput from 'react-maskinput'

function Edit() {
	const location = useLocation();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [user, setUser] = useState({
		id: '',
		name: '',
		phone_prefix: '',
		phone: '',
		taxpayer: '',
		address: '',
		address_number: '',
		address_city: '',
		address_state: '',
		full_address: '',
		site: '',
		trading_name: '',
	});

	useEffect(() => {
		if (!location.state) {
			return history.push('/clientes/profiles');
		}

		const profile = location.state.profile;

		setName(profile.name);
		setUser({
			id: profile.id,
			name: profile.name,
			phone_prefix: profile.phone_prefix,
			phone: profile.phone,
			taxpayer: profile.taxpayer,
			address_street: profile.address_street,
			address_number: profile.address_number,
			address_city: profile.address_city,
			address_state: profile.address_state,
			full_address: profile.full_address,
			site: profile.site,
			trading_name: profile.trading_name,
		});
	}, [location]);

	const handleChangeValue = (field, value) => setUser({ ...user, [field]: value });

	const style = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: '1px solid #828282',
		borderRadius: '10px',
		padding: '0px 16px 0px 16px',
		height: '40px',
		marginRight: '4px'
	}

	const divColumn = {
		display: 'flex',
		flexDirection: 'column'
	}

	const divCustom = {
		display: 'flex',
	}



	async function handleSave(e) {	
		e.preventDefault();

		var checkNumber = /\d+/g;

		if (user.name.match(checkNumber) > 0) {
			addToast(ERROR, "Ops!", "Não é permitido números no campo nome.");
		} else {
			setLoading(true);
			const resp = await PatchClient(user);
			setLoading(false);
			if (resp && resp.status === 200) {
				addToast(SUCCESS, "Dados alterados com sucesso!");
				history.push("/clientes/profiles")
			}
		}

	}

	return (
		<Container>
			<h2>Edição de Perfil</h2>
			<Content>
				<Form onSubmit={handleSave}>
					<Header>
						<p>{name}</p>
						{loading ? <LoadingSpinner /> : <Button type="submit">Salvar Alterações</Button>}
					</Header>
					<FormRow>
						<FormHalf>
							<Input label="Nome completo" value={user.name} onChange={event => handleChangeValue('name', event.target.value)} required />
						</FormHalf>
						<FormHalf row>
							<div style={divColumn}>
								<label>Telefone</label>
								<div style={divCustom}>
									<select 
										value={user.phone_prefix} 
										onChange={event => handleChangeValue('phone_prefix', event.target.value)}>
										{codigoPaises.map((item, index) => (
											<option key={index} value={item}>{item}</option>
										))}
									</select>
									<MaskInput
										mask={'(000) 00000-0000'}
										placeholder="(000) 00000-0000"
										onChange={event => handleChangeValue('phone', event.target.value.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""))}
										style={style}
										value={user.phone}
									/>
								</div>
							</div>
							<Input type="number" label="NIF/NIPC" value={user.taxpayer} onChange={event => handleChangeValue('taxpayer', event.target.value)} required />
						</FormHalf>
					</FormRow>
					<FormRow>
						<FormHalf>
							<Input label="Morada" value={user.full_address} onChange={event => handleChangeValue('full_address', event.target.value)} required />
						</FormHalf>
						{/* <FormHalf row>
							<Input label="Número" value={user.address_number} onChange={event => handleChangeValue('address_number', event.target.value)} />
						</FormHalf> */}
					</FormRow>
					<FormRow>
						<FormHalf row>
							<Input label="Cidade" value={user.address_city} onChange={event => handleChangeValue('address_city', event.target.value)} required />
							<Input label="Distrito" value={user.address_state} onChange={event => handleChangeValue('address_state', event.target.value)} required />
						</FormHalf>
						<FormHalf>
							<Input label="Site" value={user.site} onChange={event => handleChangeValue('site', event.target.value)} />
						</FormHalf>
					</FormRow>
					<FormRow>
						<FormHalf>
							<Input label="Nome Fantasia(opcional)" value={user.trading_name} onChange={event => handleChangeValue('trading_name', event.target.value)} />
						</FormHalf>
					</FormRow>
				</Form>
			</Content>
		</Container>
	);
}

export default Edit;