import React from 'react'
import {
  Container, InputText, Form, Label, Button, ValorMensalidade,
  DataInicio, DataTermino, ButtonMonth, DivInput, ChartArea
} from './styles'
import ChartMensalidadeMaior from '../../components/ChartMensalidadeMaior'
import ChartCrescimentoMensalidadeMaior from '../../components/ChartCrescimentoMensalidadeMaior'
import { useHistory } from 'react-router'
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import { useAuth } from '../../AppContext/Context';

const GestaoFinanceira = () => {
  const { user: { is_type } } = useAuth();
  const history = useHistory()

  function handleNavigation(event) {
    event.preventDefault()
    history.push("/financeiro/gestao")
  }
  return (
    <>
      <h3><b>Gestão Financeira</b></h3>
      <Container>
        <DivInput>
          <Form>
            <ValorMensalidade>
              <Label>
                Valor da mensalidade
              </Label>
              <InputText type="number" placeholder=" EUR 250,00">
              </InputText>
            </ValorMensalidade>
            <DataInicio>
              <Label>
                Data de início
              </Label> <br />
              <InputText type="date" placeholder=" 00/00/0000">
              </InputText>
            </DataInicio>
            <DataTermino>
              <Label>
                Data de término
              </Label> <br />
              <InputText type="date" placeholder=" 00/00/0000" />

            </DataTermino>
            {checkPermission(is_type, MODELS.Financeiro, FUNCTIONS.Inserir) ?
            <Button onClick={handleNavigation}>Salvar Valor</Button> : null }
          </Form>
        </DivInput>
        <ButtonMonth>
          Últimos 12 meses
        </ButtonMonth>
        <ChartArea>
          <ChartMensalidadeMaior />
          <ChartCrescimentoMensalidadeMaior />
        </ChartArea>


      </Container>
    </>
  )
}

export default GestaoFinanceira;