import styled from "styled-components";

export const Container = styled.div`
  width: 96%;
  background-color: #FFF;
  margin: 50px 0 50px 0;
  padding: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;

  div {
    margin-right: 22px;
  }
`;

export const Button = styled.button`
  background-color: ${props => props.green ? '#86fefa' : '#D5D7E3'};
  border: none;
  border-radius: 12px;
  height: 45px;
  width: 130px;
`;

export const SelectType = styled.select`
  width: 120px !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`

export const Table = styled.table`
  width: ${props => props.large ? "100%" : "auto"};
  margin-top: 32px;
  font-size: 14px;
  text-align: center;
  background: #fff;
  h1{
    color: var(--unnamed-color-131523);
  }

  thead {
    border-radius: 5px;
    margin: 10px 10px;
    text-align: center;
  }
  
  th{
    background: var(--unnamed-color-f5f6fa) 0% 0% no-repeat padding-box !important;
    color: var(--unnamed-color-828282);
    letter-spacing: var(--unnamed-character-spacing-0);
    opacity: 1;  
    padding: 12px 0px 15px 0px;
  }

  td{
    color: var(--unnamed-color-828282);
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 5px;
    text-align: center;
    max-width: 350px;
  }
`