import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Content, Table } from './styles';
import { GetListaCliente, RejectClient } from '../../../services/client'
import { addToast, SUCCESS, ERROR } from '../../../components/Notifications';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal/Modal';

function Approval() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    reason: '',
    description: ''
  });
  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    listaClient()
  }, []);

  function handleTriggerModal(customer) {
    setModal(customer);
    setPayload({ reason: '', description: '' });
  }

  function handleChangeReject(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  const handleGoToProfileApproval = (customer) => {
    return history.push({
      pathname: `/clientes/aprovacao/perfil/`,
      state: {
        profile: customer,
      }
    });
  };

  async function listaClient() {
    setLoad(true)
    const resp = await GetListaCliente()
    if (resp) {
      setData(resp.data)
      setLoad(false)
    } else {
      setLoad(false)
      addToast(ERROR, "Nenhum dado retornado")
    }
  }

  async function rejectModal() {
    if (validForm()) {
      setLoading(true);
      const resp = await RejectClient({ ...payload, client: modal.id });
      setLoading(false);
      if (resp && resp.status === 200) {
        addToast(SUCCESS, "Cliente cancelado com sucesso!");
        handleTriggerModal(false);
        listaClient();
      } else {
        addToast(ERROR, "Não foi possível realizar essa operação");
      }
    }
  }

  function validForm() {
    if (!payload.reason || !payload.description) {
      addToast(ERROR, 'Informe o Motivo da rejeição');
      return false;
    }
    return true;
  }

  return (
    <Container>
      <h2>Aprovação de Clientes</h2>
      <Content>
        <Table>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Status</td>
              <td>E-mail</td>
              <td>Telefone</td>
              <td>Funções</td>
            </tr>
          </thead>
          <tbody>
            {load === true ?
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div> :
              data.map((customer, index) => (
                <tr key={index}>
                  <td>{customer.name}</td>
                  <td>{customer.status}</td>
                  <td>{customer.email}</td>
                  <td>{customer.phone}</td>
                  <td>
                    <Button onClick={() => handleGoToProfileApproval(customer)}>Visualizar Perfil</Button>
                    <Button onClick={() => handleTriggerModal(customer)}>Rejeitar Cadastro</Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Content>
      {modal && <Modal
        loading={loading} closeModal={handleTriggerModal}
        rejectModal={rejectModal} handleChangeReject={handleChangeReject}
      />}
    </Container>
  );
}

export default Approval;