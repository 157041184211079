import styled from 'styled-components'

export const DashboardTotal = styled.div`
  display: flex-box;
  margin-top: 30px;
`

export const ButtonMonth = styled.button`
  background-color: #86fefa;
  font-size: 12px;
  height: 20px;
  width: 120px;
  border-radius: 8px;
  border: none;
  margin-top: 35px;
`

export const CardsUp = styled.div`
`

export const CardsDown = styled.div`
  display: flex;
`