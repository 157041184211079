import styled from 'styled-components';

export const Container = styled.div`
`

export const DivInput = styled.div`
  display: flex;
  margin: 2% 0 0 0;
`
export const ValorMensalidade = styled.div`
  width: 200px;
  margin-right: 6%;
`
export const DataInicio = styled.div`
  width: 200px;
  margin-right: 6%;
`

export const DataTermino = styled.div`
  width: 200px;
  margin-right: 6%;
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
`

export const Label = styled.label`
`

export const InputText = styled.input`
  width: 200px;
  height: 46px;
  margin: 16px 0 0 0;
  padding-left: 8px;

`

export const Button = styled.button`
 background-color: #86fefa;
  font-size: 12px;
  width: 200px;
  height: 46px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 66px;
  margin-right: 20px;
`

export const ButtonMonth = styled.button`
  background-color: #86fefa;
  font-size: 12px;
  height: 20px;
  width: 120px;
  border-radius: 8px;
  border: none;
  margin-top: 35px;
`
export const ChartArea = styled.div`
  display: flex;
  flex-direction: row;
  
`