import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react'
import { addToast, ERROR } from '../components/Notifications';
import { retrive } from '../services/auth';
export const AuthContext = createContext({}); 

const AuthProvider = ( props ) => {
  //mudar o state da string aq 
  const [ user, setUser ] = useState('');
  const [load, setLoad] = useState(false); 

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("@good"));
    if ( localUser ) {
      handleLogged(localUser); 
    } else {
      setLoad(true); 
    }
}, []) 

  //function to handle context states
  const handleLogged = async (localUser) => {
    const keepLogged = await retrive(localUser); 
    if (keepLogged) {
      handleUser(keepLogged, true); 
      setLoad(true);  
    } else {
      setLoad(true);
    }
  }

  function handleUser (isLogged, keep) {
    setUser(isLogged.data) 
    axios.defaults.headers.common['Authorization'] = `Token ${isLogged.data.token}`;
    // axios.interceptors.response.use((response) => {
    //   console.log(response);
    // }, (error) => { 
    //   console.log(Object.keys(error));
    //   console.log(error.response);
    //   if(error.response.status) {
    //     handleLogged();
    //     addToast(ERROR, 'Você foi desconectado', 'por favor faça o login novamente');
    //   }
    // });
    if (keep) {
      localStorage.setItem("@good", JSON.stringify({token: isLogged.data.token, id: isLogged.data.id}));
    }
  }

  function handleLogout () {
    setUser('');
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("@good")
  }

  return (
    <AuthContext.Provider value={{
      user: user,
      handleUser: handleUser,  
      handleLogout: handleLogout,
      load: load, 
    }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider; 

export const useAuth = () => {
  return useContext(AuthContext); 
} 



