import React from 'react';

const LoadingSpinner = ({
  noMargin,
}) => {
  return (
    <div 
    className={`
      text-center ${noMargin ? 'm-0' : 'm-5'}
    `}>
      <div className="spinner-border text-info" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  )
}

export default LoadingSpinner;