import React, { useState } from 'react'
import { ContainerAvaliacao, Form, Label, Button,  
  AvaliacaoSpace, FunctionEdit, InputStar, 
  LabelStar, DescricaoArea, UpDivsAvaliacao, 
  InputDescricao, LabelAvaliacao, SelectFunction } from '../CriarAvaliacao/styles'
import { FaStar } from 'react-icons/fa';
import { useHistory } from 'react-router-dom'
import { updateArtisMediation } from '../../../services/mediation';

const EditarAvaliacao = ({location}) => {
  const history = useHistory()
  
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(null);
  const [payload, setPayload] = useState({
    grade: Number(location.state.grade),
    description: location.state.description
  })
  
  function handleChange (name, value) {
    setPayload({...payload, [name]: value});
  }

  async function handleSubmit(event)  {
    event.preventDefault();
    setLoading(true);
    const response = await updateArtisMediation(location.state.id, payload);
    setLoading(false);
    if(response) {
      history.push("/mediacao/avaliacoes/artista")
    }
  }
  
  
  return (
    <>
      <h4><b>Editar Avaliação</b></h4>
      <ContainerAvaliacao>
      <Form onSubmit={handleSubmit}>
        <UpDivsAvaliacao>
        <AvaliacaoSpace>
        <Label>
          Avaliador
        </Label> <br />
        <LabelAvaliacao> {location.state.evaluator.name}
        </LabelAvaliacao> <br />
        
        <Label>
          Avaliado
        </Label> <br />
        <LabelAvaliacao>  {location.state.rated.name}
        </LabelAvaliacao> <br />
        </AvaliacaoSpace>
        
         <FunctionEdit id="alvo">
         {/* <Label>Função</Label> <br /> */}
            {/* <SelectFunction name="Artistas" id="select" disabled="true">
                <option value="Artista" selected>Artista</option>
                <option value="Cliente">Cliente</option>
            </SelectFunction> */}
            <Label>
              Avaliação <br />
              {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1; 
                return (
                <LabelStar>
                  <InputStar 
                  type="radio" 
                  name="rating" 
                  onClick={() => handleChange('grade', ratingValue)} 
                  
                  />
                  <FaStar className="star"
                   color={ratingValue <= (hover || payload.grade) ? "#86fefa" : "#e4e5e9"}
                   size={26}
                   onMouseEnter={() => setHover(ratingValue) }
                   onMouseLeave={() => setHover(null)}  /> 
                </LabelStar>
                )
              })}
            </Label>
       </FunctionEdit>
        <Button 
        disabled={loading} 
        loading={loading}
        type="submit">Salvar alteração</Button>
        
       </UpDivsAvaliacao>
       <DescricaoArea>
          <Label>
            Descrição 
          </Label> <br />
          <InputDescricao onChange={({target}) => handleChange('description', target.value)} value={payload.description}> 
          </InputDescricao>
        </DescricaoArea>
      </Form>
      </ContainerAvaliacao>

    </>
  )
}

export default EditarAvaliacao;