import styled from 'styled-components'; 

export const FormCustom = styled.form`
  background: #ffffff;
  box-shadow: 0px 1px 4px #15223214;
  flex-direction: column;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 30px;
  padding: 0px 30px;
  width: ${props => props.large ? "95%" : "auto"};
  height: 95%;

`
export const SelectGender = styled.select`
  width: auto !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`

export const SelectRecurrency = styled.select`
  width: auto !important; 
  height: 44px;
  border-radius: 8px;
  margin: 1.5% 0 0 1%;
  padding: 0 10px 0 10px;
`;

export const FirstDiv = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  // justify-content: center;
  flex-wrap: wrap;
  width: 70%;
  height: auto;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  & > div {
      width: 48%;
  }

  select{
    height: 40px;
    width: 60px;
    border-radius: 10px;
    margin-right: 5px;
  }

`

export const SecondDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  // align-items: flex-end;
  // align-content: flex-end;
  width: 30%;
  height: auto;

  select{
    border-color: #828282;
    border-radius: 15px;
    color: #828282;
    margin-top: 20px;
    width: 250px;
    height: 45px;
    padding: 7px;
  }
`

export const DivIntern = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-end;

  div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
  }

`

export const InputSlide = styled.input`
  max-width: 55px;
  border-radius: 10px;
  border: 1px solid #828282;
  padding-left: 3px;
`;

export const InputName = styled.input`
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-right: 10px;
  padding: 7px;
`

export const InpuCustom = styled.input`
  width: 140px;
  height: 50px;
  border-radius: 10px;
  padding: 7px;
  margin-right: 10px;
`

export const ElemtDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`