import styled from "styled-components";

export const Container = styled.div`
  width: 96%;
  background-color: #FFF;
  margin: 50px 0 50px 0;
  padding: 20px;
`;

export const Table = styled.table`
  td {
    border: 1px solid #d8d8d8;
    width: 200px;
    height: 47px;
    white-space: nowrap;
    padding-left: 12px;
    text-align: center;
  }
`;

export const Select = styled.select`
  height: 44px;
  border-radius: 8px;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
`

export const Button = styled.button`
  background-color: #86fefa;
  border-radius: 10px;
  border: none;
  text-transform: uppercase;
  transition: .1s;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonPlus = styled.button`
  color: green;
  margin-left: 15px;
  border: none;
  border-radius: 4px;
  width: 40px;
  height: 30px;
  font-weight: bold;
`;

export const CheckBox = styled.div`
  margin-bottom: 10px;
  
  input {
    margin-right: 10px;
  }

  label {
    font-weight: 600;
  }
`;