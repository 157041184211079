import styled from 'styled-components';

export const Container = styled.button`
  background-color: #86fefa;
  border-radius: 10px;
  border: none;
  text-transform: uppercase;
  transition: .1s;
  padding: 0px 10px;
  font-size: 16px;
  font-weight: bold;

  &:hover{
      filter: brightness(0.95);
  }
`;
