import React from 'react'
import { Bar } from 'react-chartjs-2'
import { ChartBar, AreaTotalChart, AreaInformacao } from './styles'
import Images from '../../assets/index'

const ChartCrescimentoMensalidade = () => {
  return (
    <>
      <AreaTotalChart>
        <span>Crescimento Mensalidades</span>
        <AreaInformacao>
          <span>156,927</span>
          <p>1.8<img src={Images.icon_arrow_narrow_up.default} alt="arrow"></img> </p>
        </AreaInformacao>
        <ChartBar style={{ height: 205, width: 220}}>

          <Bar
            data={{
              labels: ['Jan 20', 'Fev 20', 'Mar 20', 'Abril 20',],
              datasets: [
                {
                  label: '',
                  data: [4, 9, 3, 5, 2],
                  fill: false,
                  backgroundColor: 'rgb(30, 94, 255, 0.8)',
                  borderColor: 'rgba(30, 94, 255, 1)',
                  borderWidth: 1.5, 
                  responsive: true,
                },
                {
                  label: '',
                  data: [6, 5, 2, 4, 7],
                  fill: false,
                  backgroundColor: 'rgba(16, 220, 212, 0.8)',
                  borderColor: 'rgba(16, 220, 212, 1)',
                  borderWidth: 1.5,
                  responsive: true,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
            }}
          />
        </ChartBar>
      </AreaTotalChart>
    </>
  )
}

export default ChartCrescimentoMensalidade;