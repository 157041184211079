import React, { useState, useEffect, useRef } from "react";
import MaskInput from "react-maskinput";
import {
  FormCustom,
  InputName,
  InpuCustom,
  Hr,
  ElemtDiv,
  DivCustom,
  DivLeft,
  DivCentral,
  ElemtDivCabelo,
  ButtonCabelo,
  ElemtDivOlhos,
  ElementDivAltura,
  ElementDivCintura,
  DivRight,
  ElementDivQuadril,
  ElementDivBusto,
  DivFotos,
  ButtonAddFoto,
  ButtonSalvar,
  CardCustom,
  SelectGender,
  CustomCurrencyInput,
  SelectRecurrency,
  SelectDisabled
} from "./style";
import Slider from "../Slider";
import { useHistory, useLocation } from "react-router";
import {
  DeleteArtistPhoto,
  PatchArtista,
  SelectArtistPhoto,
  SendArtistPhoto,
} from "../../services/artist";
import { uploadArtistPhoto } from "../../services/upload";
import Notification, { addToast, SUCCESS, ERROR } from "../Notifications";
import LoadingSpinner from "../LoadingSpinner";
import { codigoPaises } from "../../Helpers";
import DeleteModal from "./DeleteModal";

import Adicao from "../../assets/adicao.png";
import { InputSlide } from "../../pages/CadastroArtista/style";
import { GetRecurring } from "../../services/recurringType";

function EditPerfil() {
  const history = useHistory();
  const location = useLocation();
  const inputRef = useRef();
  const day = [1 - 31];
  const month = [1 - 12];
  const year = [1960 - 2040];
  const maskCustomer = [day / month / year];

  const [profileArtist, setProfileArtist] = useState("");
  const [approvedAt, setApprovedAt] = useState("");

  const [selectLoad, setSelectLoad] = useState(false);
  const [phoneMask, setPhoneMask] = useState("(000) 00000-0000");
  const [modal, setModal] = useState(false);
  const [recurringTypeList, setRecurringTypeList] = useState([]);
  const [currentPhotos, setCurrentPhotos] = useState(
    location.state.profile.photos
  );

  useEffect(() => {
    if (!location.state) {
      return history.push("/artista/profiles");
    }
    const { profile } = location.state;
    setProfileArtist({
      id: profile.id,
      name: profile.name,
      email: profile.email,
      instagram: profile.instagram,
      phone_prefix: profile.phone_prefix,
      phone: profile.phone,
      gender: profile.gender,
      nick_name: profile.nick_name,
      birth_date: formatDate(profile.birth_date),
      address_city: profile.address_city,
      hair: profile.hair,
      eye: profile.eye,
      skin: profile.skin,
      stature: profile.stature,
      waist: profile.waist,
      bust: profile.bust,
      hip: profile.hip,
      status: profile.status,
      monthly_fee: profile.monthly_fee,
      expiration_date: formatDate(profile.expiration_date),
      recurring_payment_type: profile.recurring_payment_type ? profile.recurring_payment_type.id : null
    });
    loadRecurringType();
    setApprovedAt(formatDate(profile.approved_at));
  }, []);

  async function loadRecurringType () {
    try {
      const response = await GetRecurring();
      setRecurringTypeList(response);
    } catch (error) {
      console.log(error)
    }
  }

  function handleChange(name, value) {
    setProfileArtist({
      ...profileArtist,
      [name]: value,
    });
  }

  async function handleEditArtista(e) {
    e.preventDefault();
    var checkNumber = /\d+/g;

    if (profileArtist.name.match(checkNumber) > 0) {
      Notification("Ops!", "Não é permitido números no campo nome.", "error");
    } else {
      const resp = await PatchArtista({
        ...profileArtist,
        birth_date: profileArtist.birth_date + "T00:00:00",
        expiration_date: profileArtist.expiration_date + "T00:00:00"
      });

      if (resp && resp.status === 200) {
        Notification("Dados alterados com sucesso!", "", "success");
        history.push("/artista/profiles");
      }
    }
  }

  async function handleSelectPhoto(photo) {
    const _countPhotos = checkPhotosCount();
    if (_countPhotos < 4 || (_countPhotos > 3 && photo.is_active)) {
      setSelectLoad(photo.id);
      const resp = await SelectArtistPhoto(photo);
      setSelectLoad(false);
      if (resp) {
        const _new = [...currentPhotos].map((item) => {
          if (item.id === photo.id) {
            item.is_active = !item.is_active;
          }
          return item;
        });
        setCurrentPhotos(_new);
        addToast(SUCCESS, "Foto selecionada");
      } else {
        addToast(ERROR, "Não foi possível realizar essa operação");
      }
    } else {
      addToast(ERROR, "Só é possível escolher 4 fotos");
    }
  }

  async function handleFileUpload(event) {
    const imgs = event.target.files;
    const payload = new FormData();
    if (imgs.length) {
      for (const img of imgs) {
        payload.append("file", img);
      }

      let imgsUrls = [];

      try {
        const imageUpload = await uploadArtistPhoto(payload);
        imgsUrls = imageUpload.result.map((imgUpload) => imgUpload.Location);
      } catch (err) {
        if (err.response.data.message === "Maximum size of file reached") {
          return addToast(ERROR, "Foto ultrapassa limite de 6 MB");
        }
        return addToast(ERROR, "Houve um problema ao enviar essa Foto");
      }

      const resp = await SendArtistPhoto(imgsUrls[0], profileArtist.id);

      if (resp) {
        addToast(SUCCESS, "Foto enviada com sucesso");
        const _newCurrentPhotos = [...currentPhotos, resp];
        setCurrentPhotos(_newCurrentPhotos);
      } else {
        addToast(ERROR, "Houve um problema ao enviar essa Foto");
      }
    }
  }

  function checkPhotosCount() {
    const sum = currentPhotos.reduce((count, item) => {
      if (item.is_active) {
        return count + 1;
      }
      return count;
    }, 0);
    return sum;
  }

  const formatDate = (time, onlyDate) => {
    if(time) {
      const _date = new Date(time);
      if (onlyDate) {
        return `${_date.toLocaleDateString("pt-pt", { timeZone: "UTC" })}`;
      } else {
        return `${_date
          .toLocaleDateString("pt-pt", { timeZone: "UTC" })
          .split("/")
          .reverse()
          .join("-")}`;
      }
    }
  };

  function handleOpenModal(photo) {
    if (photo.is_active) {
      addToast(ERROR, "Não é possível deletar uma foto ativa");
    } else {
      setModal(photo);
    }
  }

  async function handleDeleteFoto(photo) {
    const response = await DeleteArtistPhoto(photo.id);
    if (response) {
      const _newCurrentPhotos = currentPhotos.filter(
        (item) => item.id !== photo.id
      );
      setCurrentPhotos(_newCurrentPhotos);
      setModal(false);
    } else {
      addToast(ERROR, "Não foi possível deletar a foto");
    }
  }

  return (
    <>
      <h2>Edição de Perfil</h2>
      <FormCustom onSubmit={handleEditArtista}>
        <div>
          <h2>Artista - {profileArtist.nick_name}</h2>
        </div>

        <ElemtDiv>
          <div>
            <label>Nome Completo</label>
            <br />
            <InputName
              type="text"
              value={profileArtist.name}
              onChange={(ev) => handleChange("name", ev.target.value)}
              required
            ></InputName>
            <br />
          </div>

          <div>
            <label>Email</label>
            <br />
            <InputName
              type="email"
              value={profileArtist.email}
              onChange={(ev) => handleChange("email", ev.target.value)}
              required
            ></InputName>
            <br />
          </div>

          <div>
            <label>Data Nascimento</label>
            <br />
            <InpuCustom
              type="date"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              title="Somente número nesse campo"
              value={profileArtist.birth_date}
              onChange={({ target }) =>
                handleChange("birth_date", target.value)
              }
              required
            ></InpuCustom>
          </div>
          <div>
            <label>Cidade de Atuação</label>
            <br />
            <InpuCustom
              type="text"
              value={profileArtist.address_city}
              placeholder="Ex. Belo Horizonte"
              onChange={(ev) => handleChange("address_city", ev.target.value)}
              required
            ></InpuCustom>
          </div>

          <div>
            <label>Instagram</label>
            <br />
            <InputName
              type="text"
              value={profileArtist.instagram}
              onChange={(ev) => handleChange("instagram", ev.target.value)}
              required
            ></InputName>
            <br />
          </div>

          <div style={{ marginTop: 25 }}>
            Gênero <br />
            <SelectGender
              value={profileArtist.gender}
              required
              onChange={({ target }) => handleChange("gender", target.value)}
            >
              <option hidden>Escolha um Gênero</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
              <option value="Trans Masculino">Trans Masculino</option>
              <option value="Trans Feminino">Trans Feminino</option>
              <option value="Outros">Outros</option>
              <option value="Todos">Todos</option>
            </SelectGender>
          </div>

          <div style={divColumn}>
            <label>Telefone</label>
            <div style={divCustom}>
              <select
                value={profileArtist.phone_prefix}
                onChange={({ target }) =>
                  handleChange("phone_prefix", target.value)
                }
              >
                {codigoPaises.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
              <MaskInput
                required
                mask={phoneMask}
                value={profileArtist.phone}
                placeholder={phoneMask}
                onChange={({ target }) => handleChange("phone", target.value)}
                style={style}
              />
            </div>
          </div>

          <div style={{ marginTop: 29 }}>
            Data Aprovação
            <br />
            {approvedAt ? <InpuCustom
              type="date"
              disabled
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              title="Somente número nesse campo"
              value={approvedAt}
            ></InpuCustom> : '-' }
          </div>

          <div style={{ marginTop: 25 }}>
            Valor Pagamento <br />
            <CustomCurrencyInput 
            prefix="€ "
            value={profileArtist.monthly_fee}
            onChange={(masked, raw) => {
              handleChange("monthly_fee", raw);
            }} />
          </div>

          <div style={{ marginTop: 25 }}>
            Status <br />
            {(profileArtist.status === "PAUSADO" || profileArtist.status === "APROVADO") ? <SelectGender
              value={profileArtist.status}
              required
              onChange={({ target }) => handleChange("status", target.value)}
            >
              <option hidden>Escolha um Status</option>
              <option value="APROVADO">APROVADO</option>
              <option value="PAUSADO">PAUSADO</option>
            </SelectGender> : 
            <SelectDisabled disabled>
              <option>{profileArtist.status}</option>
            </SelectDisabled>}
          </div>

          <div style={{ marginTop: 25 }}>
            Tipo Assinatura <br />
            <SelectRecurrency
              required
              value={profileArtist.recurring_payment_type}
              onChange={({ target }) => handleChange('recurring_payment_type', target.value)}>
              <option hidden>Seleciona</option>
              {recurringTypeList.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>  
              ))}
            </SelectRecurrency>
          </div>

          <div style={{ marginTop: 29 }}>
            Data Expiração
            <br />
            <InpuCustom
              type="date"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              title="Somente número nesse campo"
              value={profileArtist.expiration_date}
              onChange={({ target }) =>
                handleChange("expiration_date", target.value)
              }
              required
            ></InpuCustom>
          </div>
        </ElemtDiv>

        <DivCustom>
          <DivLeft>
            <ElemtDivCabelo>
              <label>Cabelo</label>
              <Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={
                    profileArtist.hair === "Castanho Escuro" ? true : false
                  }
                  onClick={() => handleChange("hair", "Castanho Escuro")}
                >
                  Castanho Escuro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Loiro" ? true : false}
                  onClick={() => handleChange("hair", "Loiro")}
                >
                  Loiro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Ruivo" ? true : false}
                  onClick={() => handleChange("hair", "Ruivo")}
                >
                  Ruivo
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Grisalho" ? true : false}
                  onClick={() => handleChange("hair", "Grisalho")}
                >
                  Grisalho
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={
                    profileArtist.hair === "Castanho Claro" ? true : false
                  }
                  onClick={() => handleChange("hair", "Castanho Claro")}
                >
                  Castanho Claro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.hair === "Colorido" ? true : false}
                  onClick={() => handleChange("hair", "Colorido")}
                >
                  Colorido
                </ButtonCabelo>
              </div>
            </ElemtDivCabelo>

            <ElemtDivOlhos>
              <label>Olhos</label>
              <Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={
                    profileArtist.eye === "Castanho Escuro" ? true : false
                  }
                  onClick={() => handleChange("eye", "Castanho Escuro")}
                >
                  Castanho Escuro
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.eye === "Verde" ? true : false}
                  onClick={() => handleChange("eye", "Verde")}
                >
                  Verde
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.eye === "Azul" ? true : false}
                  onClick={() => handleChange("eye", "Azul")}
                >
                  Azul
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={
                    profileArtist.eye === "Castanho Claro" ? true : false
                  }
                  onClick={() => handleChange("eye", "Castanho Claro")}
                >
                  Castanho Claro
                </ButtonCabelo>
              </div>
            </ElemtDivOlhos>
            <ElemtDivOlhos>
              <label>Pele</label>
              <Hr></Hr>
              <div>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Branca" ? true : false}
                  onClick={() => handleChange("skin", "Branca")}
                >
                  Branca
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Negra" ? true : false}
                  onClick={() => handleChange("skin", "Negra")}
                >
                  Negra
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Morena" ? true : false}
                  onClick={() => handleChange("skin", "Morena")}
                >
                  Morena
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Indígena" ? true : false}
                  onClick={() => handleChange("skin", "Indígena")}
                >
                  Indígena
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={profileArtist.skin === "Asiática" ? true : false}
                  onClick={() => handleChange("skin", "Asiática")}
                >
                  Asiática
                </ButtonCabelo>
                <ButtonCabelo
                  type="button"
                  selected={
                    profileArtist.skin ===
                    "Sul Asiática - Índia, Paquistão, etc."
                      ? true
                      : false
                  }
                  onClick={() =>
                    handleChange(
                      "skin",
                      "Sul Asiática - Índia, Paquistão, etc."
                    )
                  }
                >
                  Sul Asiática - Índia, Paquistão
                </ButtonCabelo>
              </div>
            </ElemtDivOlhos>
          </DivLeft>

          <DivCentral>
            <ElementDivAltura>
              <label>
                Altura:{" "}
                <InputSlide
                  value={profileArtist.stature}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("stature", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 400</p>
              <Slider
                domain={[0, 400]}
                values={[profileArtist.stature]}
                selected={profileArtist.stature}
                onChangeSelected={(value) => handleChange("stature", value[0])}
              />
            </ElementDivAltura>
            <ElementDivCintura>
              <label>
                Cintura:{" "}
                <InputSlide
                  value={profileArtist.waist}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("waist", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 400</p>
              <Slider
                domain={[0, 400]}
                values={[profileArtist.waist]}
                selected={profileArtist.Awaist}
                onChangeSelected={(value) => handleChange("waist", value[0])}
              />
            </ElementDivCintura>
          </DivCentral>

          <DivRight>
            <ElementDivQuadril>
              <label>
                Anca:{" "}
                <InputSlide
                  value={profileArtist.hip}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("hip", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 400</p>
              <Slider
                domain={[0, 400]}
                values={[profileArtist.hip]}
                selected={profileArtist.hip}
                onChangeSelected={(value) => handleChange("hip", value[0])}
              />
            </ElementDivQuadril>
            <ElementDivBusto>
              <label>
                Peito:{" "}
                <InputSlide
                  value={profileArtist.bust}
                  type="number"
                  onChange={({ target }) =>
                    handleChange("bust", Number(target.value))
                  }
                />{" "}
                cm
              </label>
              <Hr></Hr>
              <p>0 - 400</p>
              <Slider
                domain={[0, 400]}
                values={[profileArtist.bust]}
                selected={profileArtist.bust}
                onChangeSelected={(value) => handleChange("bust", value[0])}
              />
            </ElementDivBusto>
          </DivRight>
        </DivCustom>
        <DivFotos>
          {currentPhotos.map((photo) => (
            <CardCustom key={photo.id}>
              <button type="button" onClick={() => handleOpenModal(photo)}>
                X
              </button>
              <img src={photo.image} alt="Modelo" />
              <div style={divCustom2style}>
                {selectLoad === photo.id ? (
                  <LoadingSpinner noMargin={true} center={true} />
                ) : (
                  <>
                    <input
                      type="checkbox"
                      id={`select${photo.id}`}
                      value="Selecionar"
                      checked={photo.is_active}
                      onClick={() => handleSelectPhoto(photo)}
                    ></input>
                    <label style={Label} for={`select${photo.id}`}>
                      Selecionar
                    </label>
                  </>
                )}
              </div>
            </CardCustom>
          ))}
          <ButtonAddFoto
            type="button"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            <img src={Adicao} />
            <br /> FOTO
          </ButtonAddFoto>
          <input
            ref={inputRef}
            accept="image/png, image/jpeg"
            type="file"
            hidden
            onInput={(event) => {
              handleFileUpload(event);
            }}
            onClick={(event) => (event.target.value = null)}
          />
        </DivFotos>
        <ButtonSalvar type="submit">SALVAR ALTERAÇÕES</ButtonSalvar>
      </FormCustom>
      {modal ? (
        <DeleteModal
          handleDeleteFoto={handleDeleteFoto}
          modal={modal}
          setModal={setModal}
        />
      ) : null}
    </>
  );
}

export default EditPerfil;

const Label = {
  marginTop: "12px",
};

const divCustom2style = {
  display: "flex",
  alignItems: "flex-end",
  marginLeft: "10px",
  marginBottom: "5px",
};

const style = {
  border: "1px solid #828282",
  borderRadius: "10px",
  padding: "0px 16px 0px 16px",
  height: "40px",
};

const divCustom = {
  display: "flex",
};

const divColumn = {
  display: "flex",
  flexDirection: "column",
};
