import styled from 'styled-components';

export const Container = styled.div`
  padding: 1em 3em 3em 0px;

  & > h2 {
    margin-bottom: 56px;
  }
`;

export const Content = styled.div`
  background-color: #FFF;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 1em 2em;
`;

export const Header = styled.div`
  display: flex;
  height: 40px;
  align-items: center;

  & > div:last-child{
    justify-content: flex-end;
  }
`;

export const HeaderContent = styled.div`
  width: 50%;
  height: 40px;
  display: flex;
  
  & > p {
    padding: 0px;
    margin: 0px;
    font-size: 22px;
    font-weight: 500;
  }

  & > button {
    height: 40px;
    font-size: 14px;
    

    &:last-child{
      background-color: #DC1076;
      margin-left: 16px;
    }
  }
`;

export const Information = styled.form`
  position: relative;
  margin-top: 32px;

  & > textarea {
    margin-bottom: 76px;
  }
`;

export const InformationRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  & > div {
      width: 48%;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.p`
  font-size: 20px;
  margin: 0;
  margin-bottom: 4px;
`;

export const Value = styled.p`
  font-size: 16px;
  margin: 0;
`;

export const Photo = styled.img`
  width: 250px;
  height: 250px;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 120px;
`;

export const JobHeader = styled.div`
  background-color: rgba(24, 239, 230, 0.05);
  padding: 8px 12px 2px 16px;
`;
export const JobTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
`;
export const JobDescription = styled.p``;