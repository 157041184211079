import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Container, Content, Table, SearchContainer,
  ModalBody, ModalContainer, ModalButton, ButtonSearch
} from './styles';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Notification, { ERROR, SUCCESS } from '../../../components/Notifications';

import Button from '../../../components/Button';
import { DeleteClient, GetClienteAproved, GetSearchClient } from '../../../services/client';
import ReactPaginate from 'react-paginate';
import { checkPermission, FUNCTIONS, MODELS } from "../../../utils";
import { useAuth } from "../../../AppContext/Context";

function Profiles() {
  const { user: { is_type } } = useAuth();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [load, setLoad] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);
  const [modal, setModal] = useState(null);

  const [searchList, setSearchList] = useState([]);
  const [isSearch, setIsSearch] = useState(0);
  const [searchParams, setSearchParam] = useState({
    name: '', email: '', phone: ''
  });

  useEffect(() => {
    listaCliente()
  }, []);

  const handleGoToEdit = (id, profile) => history.push({
    pathname: `/clientes/editar/${profile.id}`,
    state: {
      profile
    }
  });

  async function listaCliente() {
    setLoad(true)
    const resp = await GetClienteAproved(currentPage)
    if (resp) {
      setData(resp.data.results)
      setLoad(false)
      const totalPages = Math.ceil(resp.data.count / itensPerPage);
      setPages(totalPages)
    } else {
      setLoad(false)
      Notification("Nenhum dado retornado", "", "error")
    }
  }

  function handlePageClick(ev) {
    setCurrentPage(ev.selected + 1)
  }

  async function handleDelete() {
    setLoadingModal(true);
    const resp = await DeleteClient(modal);
    setLoadingModal(false);
    if (resp) {
      listaCliente();
      setModal(null);
      Notification("Usuário apagado", "", SUCCESS);
    } else {
      Notification("Não foi possível apagar usuário", "", ERROR);
    }
  }

  async function submitSearch() {

    if (searchParams.name.length > 3 || searchParams.email.length > 3 || searchParams.phone.length > 3) {
      setIsSearch(1);
      const resp = await GetSearchClient(searchParams);
      setIsSearch(2);
      if (resp) {
        setSearchList(resp);
      }
    } else {
      Notification("Informe no mínimo 4 caracteres", "", "error")
    }
  }

  function handleKey(e) {
    if(e.key === 'Enter' && isSearch !== 1) {
      submitSearch();
    }
  }

  function handleChangeSearch(field, value) {
    setSearchParam({ ...searchParams, [field]: value });
  }

  function clearFilter() {
    setIsSearch(0);
    setSearchList([]);
    setSearchParam({ name: '', email: '', phone: '' });
  }

  return (
    <Container>
      <h2>Perfis de Clientes</h2>
      <Content>
        <SearchContainer>
          <label>Nome: </label>
          <input onKeyDown={handleKey} value={searchParams.name} onChange={({ target }) => handleChangeSearch('name', target.value)} />
          <label>Email: </label>
          <input onKeyDown={handleKey} value={searchParams.email} onChange={({ target }) => handleChangeSearch('email', target.value)} />
          <label>Telefone: </label>
          <input type="number" onKeyDown={handleKey} value={searchParams.phone} onChange={({ target }) => handleChangeSearch('phone', target.value)} />
          <div style={{ marginTop: 10 }}>
            <ButtonSearch azul large onClick={() => submitSearch()}>Procurar</ButtonSearch>
            <ButtonSearch onClick={() => clearFilter()}>Limpar</ButtonSearch>
          </div>
        </SearchContainer>
        <Table>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Status</td>
              <td>E-mail</td>
              <td>Telefone</td>
              <td>Funções</td>
            </tr>
          </thead>
          <tbody>
            {load === true ?
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div> :
              isSearch != 0 ?
                isSearch === 1 ?
                  <div className="text-center m-5">
                    <div className="spinner-border text-info" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                  </div>
                  :
                  searchList.map((customer, index) => (
                    <tr key={index}>
                      <td>{customer.name}</td>
                      <td>{customer.status}</td>
                      <td>{customer.email}</td>
                      <td>{customer.phone}</td>
                      <td>
                        {checkPermission(is_type, MODELS.Cliente, FUNCTIONS.Atualizar) ? <>
                          <Button onClick={() => handleGoToEdit(index, customer)}>Editar Perfil</Button>
                          <Button onClick={() => setModal(customer.id)}>Apagar Perfil</Button>
                        </>
                          : null}

                      </td>
                    </tr>
                  ))
                :
                data.map((customer, index) => (
                  <tr key={index}>
                    <td>{customer.name}</td>
                    <td>{customer.status}</td>
                    <td>{customer.email}</td>
                    <td>{customer.phone}</td>
                    <td>
                      {checkPermission(is_type, MODELS.Cliente, FUNCTIONS.Atualizar) ? <>
                        <Button onClick={() => handleGoToEdit(index, customer)}>Editar Perfil</Button>
                        <Button onClick={() => setModal(customer.id)}>Apagar Perfil</Button>
                      </>
                        : null}

                    </td>
                  </tr>
                ))}

          </tbody>

        </Table>
        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Próximo'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page'}
          previousClassName={'page'}
          nextClassName={'page'}
        />
      </Content>
      {modal &&
        <ModalContainer>
          <ModalBody>
            <div>
              <h2>Tem certeza que deseja excluir o Usuário?</h2>
            </div>
            {loadingModal ? <LoadingSpinner noMargin={true} /> : <>
              <ModalButton blue onClick={handleDelete}>EXCLUIR</ModalButton>
              <ModalButton onClick={() => setModal(null)}>CANCELAR</ModalButton>
            </>}
          </ModalBody>
        </ModalContainer>
      }
    </Container>
  );
}

export default Profiles;