import React from "react";
import { formatDate } from '../../utils';
import LoadingSpinner from "../LoadingSpinner";
import { TableCustom  } from './styles'

function TabelaNoticacoes({
  currentUpdate,
  notifications,
  handleChangeVisible
}){
  const style = {
    fontSize: 20,
  }

  const checkVisible = (_item) => {
    if(_item.visible) {
      if(_item.visible < 1) {
        return "NÃO"
      } else {
        return "SIM"
      }
    }
    return "NÃO"
  }

  return(
    <div>
      <TableCustom large style={style}>
        <thead>
          <th>Título</th>
          <th>Mensagem</th>
          <th>Email</th>
          <th>Função</th>
          <th>Visível</th>
          <th>Data de envio</th>
        </thead>
        <tbody>
          {notifications.map((item, index) => (
            <tr key={item.id}>
              <td>{item.title}</td>
              <td>{item.message}</td>
              {item.function === "Produtor" ? <td>
                {typeof(item.client) === "object" ? '' : item.client.email}
              </td> : <td>
                {typeof(item.artist) === "object" ? '' : item.artist.email}
              </td> }
              <td>{item.function}</td>
              <td>
                {currentUpdate && currentUpdate.id === item.id ? <LoadingSpinner noMargin/>
                :<select
                value={checkVisible(item)}
                onChange={({target}) => handleChangeVisible(item, target.value, index)}
                >
                  <option>NÃO</option>
                  <option>SIM</option>
                </select> }
              </td>
              <td>{formatDate(item.created_at)}</td>
            </tr>
          ))}
        </tbody>
        
      </TableCustom>
    </div>
  )
}

export default TabelaNoticacoes;