import React, { useState, useEffect } from 'react';
import {
  CreateProfile, DeleteProfile, EditProfile, GetProfiles
} from '../../services/profile';

import Input from '../../components/Input';
import Slider from "../../components/Slider";
import {
  Container, Title, RowCards, CardTitle, CardContainer,
  CardLabel, CardFooter, ButtonCard, CardBody, GridContainer,
  CharacterContainer, ButtonCharacter, Hr, Button, ModalBody,
  ModalContainer, ModalButton
} from './style';
import { addToast, ERROR, SUCCESS } from '../../components/Notifications';
import LoadingSpinner from '../../components/LoadingSpinner';
import { checkPermission, FUNCTIONS, MODELS } from "../../utils";
import { useAuth } from "../../AppContext/Context";

const INITIAL_STATE = {
  name: "",
  hair: "",
  eye: "",
  skin: "",
  age: 0,
  age_max: 400,
  stature: 0,
  stature_max: 400,
  waist: 0,
  waist_max: 400,
  hip: 0,
  hip_max: 400,
  bust: 0,
  bust_max: 400,
}

const Profile = () => {
  const { user: { is_type } } = useAuth();

  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [payload, setPayload] = useState(INITIAL_STATE);

  useEffect(() => {
    loadProfiles();
  }, [])

  async function loadProfiles() {
    setLoading(true);
    const response = await GetProfiles();
    setLoading(false);
    if (response) {
      setProfiles(response);
    }
  }

  async function handleChange(name, value) {
    if (name === 'age' || name === 'stature' || name === 'waist' ||
      name === 'hip' || name === 'bust') {
      setPayload({
        ...payload,
        [name]: value[0],
        [name + '_max']: value[1]
      });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  }

  async function handleEdit(profile) {
    const { updated_at, created_at, ...rest } = profile;
    setPayload(rest);
  }

  async function handleSubmit() {
    if (payload.name && payload.hair && payload.skin) {
      if (payload.id) {
        setSubmitLoad(true);
        const response = await EditProfile(payload);
        setSubmitLoad(false);
        if (response) {
          setPayload(INITIAL_STATE);
          addToast(SUCCESS, 'Perfil Editado com sucesso');
          loadProfiles();
        } else {
          addToast(ERROR, 'Não foi possível efetuar essa operação');
        }
      } else {
        setSubmitLoad(true);
        const response = await CreateProfile(payload);
        setSubmitLoad(false);
        if (response) {
          let _arry = [...profiles];
          _arry.push(response);
          setProfiles(_arry);
          setPayload(INITIAL_STATE);
          addToast(SUCCESS, 'Perfil Criado com sucesso');
        } else {
          addToast(ERROR, 'Não foi possível efetuar essa operação');
        }
      }
    } else {
      addToast(ERROR, 'informe os campos necessários');
    }
  }

  async function handleDeleteProfile() {
    setModalLoading(true);
    const response = await DeleteProfile(modal.id);
    setModalLoading(false);
    if (response) {
      addToast(SUCCESS, 'Perfil deletado com sucesso');
      setModal(false);
      loadProfiles();
    } else {
      addToast(ERROR, 'Não foi possível efetuar essa operação');
    }
  }

  return (
    <>
      <h2>Perfis</h2>
      <Container>
        <Title>Perfis Existentes</Title>
        <RowCards>
          {loading ? <LoadingSpinner /> :
            profiles.map((item) => (
              <CardContainer>
                <CardBody>
                  <div className="text-center mb-3">
                    <CardTitle>{item.name}</CardTitle>
                  </div>
                  <CardLabel>
                    <label>Altura:</label>
                    <span>{` ${item.stature}cm a ${item.stature_max}cm`}</span>
                  </CardLabel>
                  <CardLabel>
                    <label>Cintura:</label>
                    <span>{` ${item.waist} a ${item.waist_max}`}</span>
                  </CardLabel>
                  <CardLabel>
                    <label>Busto/Tórax:</label>
                    <span>{` ${item.bust} a ${item.bust_max}`}</span>
                  </CardLabel>
                  <CardLabel>
                    <label>Cabelo:</label>
                    <span>{` ${item.hair}`}</span>
                  </CardLabel>
                  <CardLabel>
                    <label>Olhos:</label>
                    <span>{` ${item.eye}`}</span>
                  </CardLabel>
                  <CardLabel>
                    <label>Pele:</label>
                    <span>{` ${item.skin}`}</span>
                  </CardLabel>
                </CardBody>
                <CardFooter>
                  {checkPermission(is_type, MODELS.Profile, FUNCTIONS.Atualizar) ?
                    <ButtonCard onClick={() => handleEdit(item)}>Editar</ButtonCard> : null}
                  {checkPermission(is_type, MODELS.Profile, FUNCTIONS.Excluir) ?
                    <ButtonCard gray={true} onClick={() => setModal(item)}>Excluir</ButtonCard> : null}
                </CardFooter>
              </CardContainer>
            ))
          }
        </RowCards>

        {checkPermission(is_type, MODELS.Profile, FUNCTIONS.Inserir) || checkPermission(is_type, MODELS.Profile, FUNCTIONS.Atualizar) ? <>
          <Title>Adicionar Perfil</Title>
          <GridContainer>
            <Input
              label="Nome do Perfil" type="text" required value={payload.name}
              onChange={({ target }) => handleChange('name', target.value)} />
          </GridContainer>
          <GridContainer>
            <div>
              <label>Cabelo</label><Hr></Hr>
              <CharacterContainer>
                <ButtonCharacter
                  type="button"
                  selected={payload.hair === "Castanho Escuro" ? true : false}
                  onClick={() => handleChange('hair', "Castanho Escuro")}>
                  Castanho Escuro
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.hair === "Loiro" ? true : false}
                  onClick={() => handleChange('hair', "Loiro")}>
                  Loiro
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.hair === "Ruivo" ? true : false}
                  onClick={() => handleChange('hair', "Ruivo")}>
                  Ruivo
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.hair === "Grisalho" ? true : false}
                  onClick={() => handleChange('hair', "Grisalho")}>
                  Grisalho
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.hair === "Castanho Claro" ? true : false}
                  onClick={() => handleChange('hair', "Castanho Claro")}>
                  Castanho Claro
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.hair === "Colorido" ? true : false}
                  onClick={() => handleChange('hair', "Colorido")}>
                  Colorido
                </ButtonCharacter>
              </CharacterContainer>

              <label>Olhos</label><Hr></Hr>
              <CharacterContainer>
                <ButtonCharacter
                  type="button"
                  selected={payload.eye === "Castanho Escuro" ? true : false}
                  onClick={() => handleChange("eye", "Castanho Escuro")}>
                  Castanho Escuro
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.eye === "Verde" ? true : false}
                  onClick={() => handleChange("eye", "Verde")}>
                  Verde
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.eye === "Azul" ? true : false}
                  onClick={() => handleChange("eye", "Azul")}>
                  Azul
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.eye === "Castanho Claro" ? true : false}
                  onClick={() => handleChange("eye", "Castanho Claro")}>
                  Castanho Claro
                </ButtonCharacter>
              </CharacterContainer>
              <label>Pele</label><Hr></Hr>
              <CharacterContainer>
                <ButtonCharacter
                  type="button"
                  selected={payload.skin === "Branca" ? true : false}
                  onClick={() => handleChange("skin", "Branca")}>
                  Branca
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.skin === "Negra" ? true : false}
                  onClick={() => handleChange("skin", "Negra")}>
                  Negra
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.skin === "Morena" ? true : false}
                  onClick={() => handleChange("skin", "Morena")}>
                  Morena
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.skin === "Indígena" ? true : false}
                  onClick={() => handleChange("skin", "Indígena")}>
                  Indígena
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.skin === "Asiática" ? true : false}
                  onClick={() => handleChange("skin", "Asiática")}>
                  Asiática
                </ButtonCharacter>
                <ButtonCharacter
                  type="button"
                  selected={payload.skin === "Sul Asiática - Índia, Paquistão, etc." ? true : false}
                  onClick={() => handleChange("skin", "Sul Asiática - Índia, Paquistão, etc.")}>
                  Sul Asiática - Índia, Paquistão, etc.
                </ButtonCharacter>
              </CharacterContainer>
            </div>

            <div>
              <label>Idade</label><Hr></Hr>
              <p>{payload.age} - {payload.age_max}</p>
              <Slider
                mode={2} domain={[-1, 400]} values={[payload.age, payload.age_max]}
                onChangeSelected={(value) => handleChange('age', value)} />

              <label>Altura:</label><Hr></Hr>
              <p>{payload.stature}cm - {payload.stature_max}cm</p>
              <Slider
                domain={[-1, 400]} values={[payload.stature, payload.stature_max]}
                onChangeSelected={(value) => handleChange('stature', value)} />

              <label>Cintura:</label><Hr></Hr>
              <p>{payload.waist}cm - {payload.waist_max}cm</p>
              <Slider
                domain={[-1, 400]} values={[payload.waist, payload.waist_max]}
                onChangeSelected={(value) => handleChange('waist', value)} />
            </div>

            <div>
              <label>Quadril: {payload.hip} cm</label><Hr></Hr>
              <p>{payload.hip}cm - {payload.hip_max}cm</p>
              <Slider
                domain={[-1, 400]} values={[payload.hip, payload.hip_max]}
                onChangeSelected={(value) => handleChange('hip', value)} />

              <label>Busto/Torax: {payload.bust} cm</label><Hr></Hr>
              <p>{payload.bust}cm - {payload.bust_max}cm</p>
              <Slider
                domain={[-1, 400]} values={[payload.bust, payload.bust_max]}
                onChangeSelected={(value) => handleChange('bust', value)} />
            </div>
          </GridContainer>
          <div className="d-flex justify-content-end">
            {submitLoad ? <LoadingSpinner /> : <Button onClick={handleSubmit}>Salvar Perfil</Button>}
          </div>
        </> : null}

        {modal ? <ModalContainer>
          <ModalBody>
            <div>
              <h2>Tem certeza que deseja excluir o Perfil?</h2>
            </div>
            {modalLoading ? <LoadingSpinner noMargin={true} /> : <>
              <ModalButton blue onClick={handleDeleteProfile}>EXCLUIR</ModalButton>
              <ModalButton onClick={() => setModal(false)}>CANCELAR</ModalButton>
            </>}
          </ModalBody>
        </ModalContainer> : null}
      </Container>
    </>
  );
}

export default Profile;