import React, { useState } from 'react';

import { CreateAdmin, UpdateAdmin } from '../../../services/admin';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast, ERROR, SUCCESS } from '../../../components/Notifications';
import Input from '../../../components/Input';
import {
  ModalBody, ModalButton, ModalContainer
} from './styles';
import { Select } from '../style';

const ModalUserAdmin = ({ selected, types, toggleModal }) => {

  const [payload, setPayload] = useState(selected.id ?
    {
      id: selected.id,
      name: selected.name,
      email: selected.email,
      is_type: selected.is_type.id
    }
    : {});
  const [loading, setLoading] = useState(false);

  function handleChange(name, value) {
    setPayload({ ...payload, [name]: value });
  }

  async function handleSubmit() {
    if (payload.name && payload.email && payload.is_type) {
      if (payload.id) {
        setLoading(true);
        const response = await UpdateAdmin(payload.id, payload);
        setLoading(false);
        if (response) {
          addToast(SUCCESS, 'Usuário editado com sucesso');
          toggleModal(false);
        }
      } else {
        if (payload.password) {
          setLoading(true);
          const response = await CreateAdmin(payload);
          setLoading(false);
          if (response) {
            addToast(SUCCESS, 'Usuário criado com sucesso');
            toggleModal(false);
          }
        } else {
          addToast(ERROR, 'Informe todos os campos');
        }
      }
    } else {
      addToast(ERROR, 'Informe todos os campos');
    }
  }

  return (
    <ModalContainer>
      <ModalBody>
        <div className='text-center'>
          <h2>{payload.id ? 'Editar Usuário' : 'Novo Usuário Admin' } </h2>
        </div>
        <Input label="Nome" value={payload.name}
          onChange={({ target }) => handleChange('name', target.value)} />
        <Input label="Email" value={payload.email}
          onChange={({ target }) => handleChange('email', target.value)} />
        {payload.id ? null : <Input label="Senha" type="password"
          onChange={({ target }) => handleChange('password', target.value)} />}
        <label>Tipo de usuário: </label> <br />
        <Select
          value={payload.is_type ? payload.is_type : ''}
          onChange={({ target }) => handleChange('is_type', Number(target.value))}>
          <option hidden>Escolha um Tipo</option>
          {types.map((type) => (
            <option value={type.id} key={type.id}>
              {type.name}
            </option>
          ))}
        </Select>
        {loading ? <LoadingSpinner noMargin={true} /> : <div className="d-flex justify-content-end">
          <ModalButton blue onClick={handleSubmit}>{payload.id ? 'EDITAR' : 'CRIAR'}</ModalButton>
          <ModalButton onClick={() => toggleModal(false)}>CANCELAR</ModalButton>
        </div>}
      </ModalBody>
    </ModalContainer>
  );
}

export default ModalUserAdmin;