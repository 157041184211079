import styled from "styled-components";

 export const LoginStyled = styled.div`
    background-color: #F5F6FA; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
  `
  export const FirstColumn = styled.div`
    width: 45%;
    min-height: 100%;
    justify-content: start;
    padding-top: 4rem;
  `

  export const Form = styled.form `
   margin-right: 2rem;
   padding-left: 4.3rem;
`
  export const Logo = styled.img `
    max-width: 45%;
    margin: 0 0 8% 3%;
  `  

  export const FormText = styled.p`
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 12% 6% 0;
  `

export const InputText = styled.input`
  height: 20%;
  width: 90%;
`

export const Input = styled.input`
  background-color: #86fefa;
  &:checked {
  background-color: #86fefa;  
  border: solid #86fefa ;
  border-width: 0 1px 1px 0;
  }
`

export const Label  = styled.label`
  margin-top: 3%;
  
`
export const ForgotPasswordLink = styled.a`
  text-decoration: none !important;
  color: #828282;
  font-size: 12px;
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin: 2% 0 3% 0;
  cursor: pointer;
  &:hover {
    color: #828282;
  }

`

export const LabelCheckBox  = styled.label`
  color: #828282;
  font-size: 12px;
  margin-top: 0.7%;
`
export const ButtonGetIn = styled.button`
  background-color: ${props => props.loading ? "#828282": "#86fefa"};
  padding: 2% 40%;
  margin: 8.7% -2% 15% -2%;
  border-radius: 30px;
  border: none;
  color: #212731;
`

export const Footer = styled.footer `
  color: #828282;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-top: 4%;
`

export const SecondColumn = styled.div`
      width: 55%;
      min-height: 100%;
`

export const Imagembackground = styled.img`
    height: 100%;
    width: 100%;
  `
  
export const FooterForgotPassword = styled.footer`
  color: #828282;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-top: 14%;
`
export const FormTextForgotPassWord = styled.div`
  font-size: 16px;
  margin: 5% 12% 6% 0;
`

export const TextInformationPassWord = styled.p`
  margin-top: 5%;
  text-align: justify;
`