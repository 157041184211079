import React, { useState } from 'react' 
import { 
  Container, Form, Button,
  InputsArea, DescricaoAreaClass, UpDivs, InputDescricao 
} from '../AvaliacoesArtistas/CriarAvaliacao/styles';
import { Label, RowInput, InputTextClass } from './styles';
import { useHistory } from 'react-router-dom' 
import { createClassVideo } from '../../services/class';

const CriarAula = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    title: '',
    subtitle: '',
    link: '',
    description: '',
    order: 0
  });

  function handleChange(name, value) {
    setPayload({...payload, [name]: value});
  }

  async function handleSubmit(event){
    event.preventDefault();
    const url = new URL(payload.link);
    const link = new URLSearchParams(url.search).get("v");

    const _payload = { ...payload, link: link}

    setLoading(true);
    const response = await createClassVideo(_payload);
    setLoading(false);
    if(response) {
      history.push("/aulas/gestao")
    }
  }

  return (
    <>
      <h4><b>Nova Aula</b></h4>
      <Container>
        <Form onSubmit={handleSubmit}>
        <UpDivs>
          <InputsArea>
            <Label>
              Título da Aula
            </Label> <br />
            <InputTextClass 
            required
            onChange={(event) => handleChange("title", event.target.value)} 
            placeholder="Informe o título desta aula">
            </InputTextClass> <br />
            <Label>
              Subtítulo da Aula
            </Label> <br />
            <InputTextClass 
            required
            onChange={(event) => handleChange("subtitle", event.target.value)} 
            placeholder="Informe o subtítulo desta aula">
            </InputTextClass> <br />
            <Label>
              Link da Aula
            </Label> <br />
            <InputTextClass 
            required
            onChange={(event) => handleChange("link", event.target.value)} 
            placeholder="Insira o link da vídeo aula">
            </InputTextClass>
            <RowInput>
              <Label noMargin>Posição</Label>
              <InputTextClass
                small
                type="number"
                required
                value={payload.order}
                onChange={(event) => handleChange("order", event.target.value)} />
            </RowInput>
          </InputsArea>
          {loading ? 
          <div className="text-center m-5">
            <div className="spinner-border text-info" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div> : <Button type="submit">Salvar Aula</Button> 
          }
        </UpDivs>
        <DescricaoAreaClass>
          <Label>
            Descrição 
          </Label> <br />
          <InputDescricao 
          required
          onChange={(event) => handleChange("description", event.target.value)} 
          placeholder="Descreva um pouco do conteúdo deste vídeo"> 
          </InputDescricao>
      </DescricaoAreaClass>
    </Form>

      </Container>
    </>
  )
}

export default CriarAula;