import styled from 'styled-components'

export const DivMain = styled.div`
  position: absolute; 
  z-index: 2;
`

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000031;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

`

export const ModalContainer = styled.div`
  width: 40%;
  height: 34%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: absolute;
  z-index: 4;
`

export const DivTitle = styled.div`
  display: inline-block;
  letter-spacing: 0px;
  color: #131523;
  opacity: 1;
  text-align: left;

  h3{
    font-weight: bold;
    text-align: left;
  }
`

export const Body = styled.div`
  color: #000;
  flex: 50%;
  text-align: left;

  label{
    margin-top: 25px;
    font-size: 18px;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 1%;
`

export const ButtonEdit = styled.button`
  background: ${props => props.azul ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : "#DC1076 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  height: 28px;
  font-weight: 500;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 0 7px;
`