import React from 'react'
import Youtube from 'react-youtube'

 const YoutubeVideo = (props) => {
  const opts = {
    height: '150',
    width: '280', 
    playerVars: {
      autoplay: 1,
    },
  }; 
  const videoId = '_nBlN9yp9R8'
  function videoOnReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

    return (
      <>
        <Youtube 
        videoId={props.videoId} 
        opts={opts} 
        onReady={videoOnReady} />
      </>
    )
}

export default YoutubeVideo;