import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000031;
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export const ModalBody = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;

  div {
    margin-bottom: 20px
  }
`;

export const ModalButton = styled.button`
  background: ${props => props.blue ? "var(--unnamed-color-86fefa) 0% 0% no-repeat padding-box" : props.red ? "#DC1076 0% 0% no-repeat padding-box" : "#707070 0% 0% no-repeat padding-box"};
  border: none;
  border-radius: 5px;
  opacity: 1;
  width: 120px;
  height: 37px;
  font-weight: 600;
  font-size: ${props => props.font12 ? "12px" : "16px"};
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 5px;
`