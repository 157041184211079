import styled from 'styled-components'

export const TotalChart = styled.div`
  margin-top: 10px;
  padding-left: 3px;
  display: flex-box;
  width: 48%;
  height: 420px;
  background-color: #FFF;
  border-radius: 10px;
  padding-top: 16px;
  
  span{
      font-weight: bold;
      font: 20px;
      margin-left: 8px;  
      font-size: 15px;
      height: 40px;
    }
`

export const TotalContainer = styled.div`
  display: flex;
  margin: 10% 0 0 0;
` 



