import styled from 'styled-components'; 

export const FormCustom = styled.form`
  background: #ffffff;
  box-shadow: 0px 1px 4px #15223214;
  opacity: 1;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-right: 30px;
  padding: 20px;
  width: ${props => props.large ? "95%" : "auto"};
  height: 85%;

  div{
    display: flex;
    flex-wrap: wrap;
  }

  h6{
    color: var(--unnamed-color-131523);
    display: flex;
    flex-wrap: no-wrap;
    font-size: 14px;
  }
  span{
    color: var(--unnamed-color-828282);
    font-size: 14px;
    margin: 0 7px 0 3px;
  }
`

export const SectionCustom = styled.section`
  display: contents;
`

export const CardCustom = styled.figcaption`
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 6px;
  float: left;
  margin-right: 10px;
  width: 135px;
  margin-bottom: 10px;

  img{
    border-radius: 6px;
    width: 133px;
    height: 200px;
  }

  input{
    width: 20px;
    height: 20px;
  }

  label{
    font-size: 12px;
    float: right;
    margin-left: 5px;
  }
`