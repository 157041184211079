import React from 'react';
import { Line } from 'react-chartjs-2'
import { ChartContainer, DataBox, CharLayout, AllChartSpace } from './styles';
import Images from '../../assets/index'


const options = {
  maintainAspectRatio: false,
  scales: {
    x: {
        min: 0,
        grid: {
            display: false,
        }
    },
    y: {
        min: 0,
        grid: {
          display: false,
        },
        ticks: {
          display: false
        }
    },
  },
  plugins:{
    legend: {
     display: false
    }
  },
};
const LineChart = () => {
  return (
    <>
    <AllChartSpace>
    <ChartContainer>
      <span>Clientes Cadastrados</span>
       <DataBox>
        <h4>250</h4>
        <p><span>2.3</span><img src={Images.icon_arrow_narrow_up1.default} alt="arrow"></img>  no último mes</p>
      </DataBox>
      <CharLayout style={{height: 80, width: 140}}>
      <Line 
        data={{
          labels: [' ', ' ', ' ', ' ', ' ', ' '],
          datasets:[
            {
              label: '',
              data: [10,12,6,12, 6, 9],
              fill: true,
              backgroundColor: 'rgba(177, 217, 255, 0.8)',
              borderColor: '#0058FF',
              borderWidth: 1,
              pointRadius:0,
              responsive: true,
              
            },
          ],
        }}
        options={options}
      />
      </CharLayout>
      </ChartContainer>
      <ChartContainer>
      <span>Artistas Cadastrados</span>
      <DataBox>
        <h4>500</h4>
        <p><span>2.3</span><img src={Images.icon_arrow_narrow_up1.default} alt="arrow"></img>  no último mes</p>
      </DataBox>
      <CharLayout style={{height: 80, width: 140}}>
      <Line 
        data={{
          labels: [' ', ' ', ' ', ' ', ' ', ' '],
          datasets:[
            {
              label: '',
              data: [10,12,6,12, 6, 9],
              fill: true,
              backgroundColor: 'rgba(177, 217, 255, 0.8)',
              borderColor: '#0058FF',
              borderWidth: 1,
              pointRadius:0,
            },
          ],
        }}
          options={options}
      />
      </CharLayout>
    </ChartContainer>
    <ChartContainer>
      <span>Jobs Cadastrados</span>
      <DataBox>
        <h4>450</h4>
        <p><span>2.3</span><img src={Images.icon_arrow_narrow_up1.default} alt="arrow"></img>  no último mes</p>
      </DataBox>
      <CharLayout style={{height: 80, width: 140}}>
      <Line 
        data={{
          labels: [' ', ' ', ' ', ' ', ' ', ' '],
          datasets:[
            {
              label: '',
              data: [10,12,6,12, 6, 9],
              fill: true,
              backgroundColor: 'rgba(177, 217, 255, 0.8)',
              borderColor: '#0058FF',
              borderWidth: 1,
              pointRadius:0,
            },
          ],
        }}
        options={options}
      />
      </CharLayout>
    </ChartContainer>
    </AllChartSpace>
    </>
  )
}

export default LineChart;