import styled from 'styled-components'

export const TotalChart = styled.div`
  margin-top: 10px;
  padding-left: 3px;
  display: flex-box;
  width: 670px;
  background-color: #FFF;
  border-radius: 10px;
  margin-bottom: 50px;
  span{
      font-weight: bold;
      margin: 10px 0 0 7px;
      font-size: 15px;
      height: 20px;
    }
`

export const TotalContainer = styled.div`
  display: flex;
` 



