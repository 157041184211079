import React, { useState } from 'react';
import { Container, Content, Form, FormRow, AlignEnd } from './styles';
import { useHistory } from 'react-router';
import Notification from '../../../components/Notifications';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import { PostInviteClient } from '../../../services/client';

function Invite() {

    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ description, setDescription ] = useState("")
    const history = useHistory();

    const handleSubmit = e => {
        e.preventDefault();
    }

    async function handleInvite(){
        const data = {
            name: name,
            email: email,
            description: description,
        }

        const resp = await PostInviteClient(data);
        Notification("Convite enviado com sucesso!", "", "success")
        history.push("/clientes/profiles")
    }

    return (
        <Container>
            <h2>Convidar Clientes</h2>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <FormRow>
                        <Input type="text" label="Nome completo" onChange={(ev) => setName(ev.target.value)}/>
                        <Input type="email" label="Email" onChange={(ev) => setEmail(ev.target.value)}/>
                    </FormRow>
                    <Textarea label="Mensagem" onChange={(ev) => setDescription(ev.target.value)}/>
                    <AlignEnd>
                        <Button onClick={handleInvite}>Enviar Convite</Button>
                    </AlignEnd>
                </Form>
            </Content>
        </Container>
    );
}

export default Invite;