import React from 'react';
import Images from '../../assets/index'
import { HeaderArea, Img } from './styles';
import { useAuth } from '../../AppContext/Context'

const Header = () => {
  const { user, handleLogout } = useAuth();

  return (
    <HeaderArea>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <Img className="navbar-brand" src={Images.logo.default} alt="logo" width="101px" />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto me-3 align-middle">
              <li className="nav-item me-4 mt-1">
                <img src={Images.icon_bell.default} alt="notify" />
              </li>
              <li className="nav-item ms-2">
                <img src={Images.icon_users.default} alt="user" width="30px" />
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link me- dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {user.name}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><button class="dropdown-item" onClick={handleLogout}>Logout</button></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </HeaderArea>
  )
}

export default Header;