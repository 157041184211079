import React from 'react'

import './style.css'
import { ButtonEdit } from '../TabelaAprovacaoArtista/styles'

function Modal({ closeModal, rejectModal, loading, handleChangeReject }){

  const style ={
    border: '1px solid #828282',
    borderRadius: '6px',
    width: '99%',
    height: '9%',
    maxHeight: '10%',
    marginTop: '10px'
  }

  return(
    <>
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="title">
            <h3>Rejeitar Cadastro</h3>
          </div>
          <div className="body">
            <div>
              <label>Motivo</label>
            </div>
            <input id="input" style={style} type="text" 
            onChange={({target}) => handleChangeReject("reason", target.value)}/>
            <div>
              <label>Observação</label>
            </div>
            <textarea cols="50" onChange={({target}) => handleChangeReject("description", target.value)}>
            </textarea>
          </div>
          <div className="footer">
            {loading ? 
            <div className="text-center m-5">
              <div className="spinner-border text-info" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            :
            <>
            <ButtonEdit onClick={() => closeModal(false)}>CANCELAR</ButtonEdit>
            <ButtonEdit azul onClick={() => rejectModal()}>CONFIRMAR REJEIÇÃO</ButtonEdit>
            </>}
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal;