import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom' 
import ReactPaginate from 'react-paginate';

import YoutubeVideo from '../../components/GestaoAulasVideos'
import { 
  Container, Button, VideoArea, VideoAreaIndividual, ButtonEdit,
  VideoDescription, ButtonVideoOff, ButtonArea, ButtonsContainer,
  DivMain, DivTitle, ModalBackground, ModalContainer, Body, Footer
} from './styles';
import { getClassVideos, updateClassVideo, deleteClass } from '../../services/class';
import { checkPermission, FUNCTIONS, MODELS } from '../../utils';
import { useAuth } from "../../AppContext/Context";
import LoadingSpinner from '../../components/LoadingSpinner';

const GestaoAulas = () => {
  const history = useHistory();
  const { user: { is_type } } = useAuth();

  const [itensPerPage, setItensPerPage ] = useState(10);
  const [currentPage, setCurrentPage ] = useState(1);
  const [totalPages, setTotalPages ] = useState(1);

  const [loading, setLoading] = useState(false);
  const [activeLoading, setActiveLoading] = useState('');

  const [classList, setClassList] = useState([]);
  
  const [modal, setModal] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  
  useEffect(() => {
    loadClass();
  }, [currentPage])

  const loadClass = async () => {
    setLoading(true);
    const response = await getClassVideos(currentPage);
    setLoading(false);
    if(response) {
      const totalPages = Math.ceil(response.count / itensPerPage);
      setTotalPages(totalPages);
      setClassList(response.results);
    }
  }

  async function handleActiveClass (_class) {
    const payload = { is_active: _class.is_active ? false : true };
    setActiveLoading(_class.id);
    const response = await updateClassVideo(_class.id, payload);
    setActiveLoading('');
    if(response) {
      loadClass();
    }
  }

  function handleNavigation(event){
    event.preventDefault()
    history.push("/aulas/criar-aula")
  }
  

  function handlePageClick(ev){
    setCurrentPage(ev.selected + 1)
  }

  async function handleRemove() {
    setDeleteLoading(true);
    const response = await deleteClass(modal.id);
    setDeleteLoading(false);
    if(response) {
      setModal(null);
      loadClass();
    }
  }
  
  return (
    <>
      <h4><b>Gestão de Aulas</b></h4>
      <ButtonArea>
        {checkPermission(is_type, MODELS.Aula, FUNCTIONS.Inserir) ? <Button onClick={handleNavigation}>Criar nova aula</Button> : null}
      </ButtonArea>
      <Container>
        {loading ? 
        <div className="text-center m-5">
          <div className="spinner-border text-info" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div> 
        :
        <VideoArea>
          {classList.map((item, index) => (<div key={item.id}>
            <span>{item.subtitle}</span>
            <VideoAreaIndividual>  
              <YoutubeVideo videoId={item.link} />
              <VideoDescription>
                <h5 onClick={() => history.push({pathname: '/aulas/editar-aula', state: {...item} })}>
                  {item.title}
                </h5>
                <p>{item.description}</p>
                <p>Posição: {item.order}</p>
              </VideoDescription>
              {checkPermission(is_type, MODELS.Aula, FUNCTIONS.Atualizar) ? 
              activeLoading === item.id ? 
              <div className="text-center m-5">
                <div className="spinner-border text-info" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div> : <ButtonsContainer>
                <ButtonVideoOff is_active={item.is_active} onClick={() => handleActiveClass(item)}>
                  {item.is_active ? 'DESATIVAR' : 'ATIVAR'} 
                </ButtonVideoOff>
                <ButtonVideoOff red onClick={() => setModal(item)}>
                  APAGAR
                </ButtonVideoOff>
              </ButtonsContainer>
              : null }
            </VideoAreaIndividual>
            </div>
          ))}
        </VideoArea> }
        <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Próximo'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
        pageClassName={'page'}
        previousClassName={'page'}
        nextClassName={'page'} />

        {modal ? <DivMain>
        <ModalBackground>
          <ModalContainer>
            <DivTitle>
              <h3>Excluir Vídeo</h3>
            </DivTitle>
            <Body>
              <div>
                <label>Você tem certeza que quer excluir esse vídeo?</label><br />
                <label><b>Essa ação é irreversível!</b></label>
              </div>
            </Body>
            <Footer>
              {deleteLoading ? <LoadingSpinner /> : <>
                <ButtonEdit onClick={() => setModal(false)}>CANCELAR</ButtonEdit>
                <ButtonEdit azul onClick={() => handleRemove()}>EXCLUIR</ButtonEdit> 
              </> }
            </Footer>
          </ModalContainer>
        </ModalBackground>
      </DivMain> : null}
      </Container>
    </>
  )
}

export default GestaoAulas;